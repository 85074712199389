import { useMutation } from "@apollo/client";
import Modal from "../patterns/Modal";
import { CREATE_CRM_INTEGRATION } from "../graphql/mutations";
import { FormEvent, useCallback, useMemo, useRef, useState } from "react";
import { CrmTypeChoices } from "../__generated__/graphql";
import SelectInput from "../patterns/forms/SelectInput";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import toast from "react-hot-toast";

interface Props {
  closeModal: () => void;
}

export default function NewIntegrationModal({ closeModal }: Props) {
  const formRef = useRef<HTMLFormElement>();
  const [createCrmIntegrationMutation] = useMutation(CREATE_CRM_INTEGRATION);
  const [type, setType] = useState<CrmTypeChoices>();
  const [isSaving, setIsSaving] = useState(false);
  const options = useMemo(
    () =>
      Object.keys(CrmTypeChoices)
        .filter((k) => k === "Hubspot" || k === "Salesforce")
        .map((k) => {
          return {
            label: k,
            value: CrmTypeChoices[k],
          };
        }),
    [],
  );
  const createCrmIntegration = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setIsSaving(true);
      try {
        const response = await createCrmIntegrationMutation({
          variables: { type, config: "{}" },
          refetchQueries: ["GetCrmIntegrations", "GetSession"],
        });
        const redirect = response.data.createCrmIntegration.redirect;
        if (redirect) {
          if (window.location.port === "3000") {
            window.location.replace(`//localhost:8000/${redirect}`);
          } else {
            window.location.replace(`/${redirect}`);
          }
        } else {
          toast.success("Integration created");
          closeModal();
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setIsSaving(false);
      }
    },
    [closeModal, createCrmIntegrationMutation, type],
  );
  return (
    <Modal close={closeModal} title="Add an CRM integration">
      <form
        ref={formRef}
        className=""
        action="#"
        onSubmit={createCrmIntegration}
      >
        <SelectInput
          label="Choose the integration you would like to use"
          placeholder="Choose an integration"
          options={options}
          required={true}
          onChange={(s) => {
            setType(s as CrmTypeChoices);
          }}
        />

        <PrimaryButton
          label="Create integration"
          isDisabled={isSaving}
          onClick={() => formRef.current.requestSubmit()}
          fullWidth={true}
        />
      </form>
    </Modal>
  );
}
