import { graphql } from "../__generated__";

export const GET_BEHAVIORS = graphql(`
  query GetAllBehaviors {
    allBehaviors {
      id
      name
      status
      targetMemberType
      audience
      observing
      belongsToLifecycleStageOrObjective
      deleted
    }
  }
`);

export const GET_COHORTS = graphql(`
  query GetAllCohorts {
    allCohorts {
      id
      name
      status
      targetMemberType
      audience
      observing
      stats {
        entered {
          count
          timeSeries {
            x
            y
          }
        }
        observing {
          count
          timeSeries {
            x
            y
          }
        }
        completed {
          count
          timeSeries {
            x
            y
          }
        }
        timeToCompletion {
          average
          plot {
            x
            y
          }
        }
        totalPossibleMembers
      }
      deleted
    }
  }
`);

export const GET_CAMPAIGNS = graphql(`
  query GetCampaigns($onlyArchived: Boolean) {
    allCampaigns(onlyArchived: $onlyArchived) {
      id
      name
      campaignType
      targetMemberType
      started
      status
      isBehavior
      definition
      deleted
      stats {
        completed {
          count
        }
        entered {
          count
        }
        exited {
          count
        }
        timeToCompletion {
          average
        }
      }
    }
    allPeople(limit: 1) {
      total
    }
  }
`);

export const GET_REFERRALS = graphql(`
  query GetReferrals {
    allReferrals {
      id
      name
      status
      actionsOnReferred
      stats {
        totalReferrals
        totalReferrers
        totalReferees
      }
    }
  }
`);

export const GET_BEHAVIOR = graphql(`
  query GetBehaviorById($id: String!) {
    behaviorById(id: $id) {
      id
      name
      status
      targetMemberType
      audience
      observing
      actionsOnEnter
      actionsOnCompletion
      actionsWhileObserving
      actionsOnExit
      stats {
        completed {
          count
          timeSeries {
            x
            y
          }
        }
        entered {
          count
          timeSeries {
            x
            y
          }
        }
        observing {
          count
          timeSeries {
            x
            y
          }
        }
        timeToCompletion {
          average
          plot {
            x
            y
          }
        }
      }
    }
  }
`);

export const GET_COHORT = graphql(`
  query GetCohortById($id: String!) {
    cohortById(id: $id) {
      id
      name
      status
      targetMemberType
      audience
      observing
      aiSummarySettings {
        action
        period
      }
      stats {
        completed {
          count
          timeSeries {
            x
            y
          }
        }
        entered {
          count
          timeSeries {
            x
            y
          }
        }
        observing {
          count
          timeSeries {
            x
            y
          }
        }
        timeToCompletion {
          average
          plot {
            x
            y
          }
        }
      }
    }
  }
`);

export const GET_CAMPAIGN = graphql(`
  query GetCampaign($id: String!) {
    campaignById(id: $id) {
      id
      name
      started
      campaignType
      targetMemberType
      status
      isBehavior
      definition
      deleted
      averageTimeToCompletionInSeconds
      stats {
        completed {
          count
          timeSeries {
            x
            y
          }
        }
        entered {
          count
          timeSeries {
            x
            y
          }
        }
        exited {
          count
          timeSeries {
            x
            y
          }
        }
        timeToCompletion {
          average
          plot {
            x
            y
          }
        }
      }
    }
    allPeople(limit: 1) {
      total
    }
    allOrgs(limit: 1) {
      total
    }
    messagingSenders {
      id
      displayName
      externalId
    }
    incentives {
      id
      name
      type
      cost
    }
  }
`);

export const GET_REFERRAL = graphql(`
  query GetReferral($id: String!) {
    referralById(id: $id) {
      id
      name
      status
      actionsOnReferred
      stats {
        totalReferrals
        totalReferrers
        totalReferees
      }
    }
    messagingSenders {
      id
      displayName
      externalId
    }
  }
`);

export const GET_BEHAVIORS_BY_ID = graphql(`
  query GetBehaviorsById($ids: [String!]!) {
    behaviorsById(ids: $ids) {
      id
      name
      status
      targetMemberType
      audience
      observing
      stats {
        entered {
          count
          timeSeries {
            x
            y
          }
        }
        observing {
          count
          timeSeries {
            x
            y
          }
        }
        completed {
          count
          timeSeries {
            x
            y
          }
        }
        timeToCompletion {
          average
          plot {
            x
            y
          }
        }
        totalPossibleMembers
      }
      deleted
    }
  }
`);

export const GET_EVENTS = graphql(`
  query GetEvents {
    allEvents
  }
`);

export const GET_PEOPLE = graphql(`
  query GetPeople(
    $query: String
    $filter: String
    $cursor: String
    $limit: Int
  ) {
    allPeople(query: $query, filter: $filter, cursor: $cursor, limit: $limit) {
      total
      pageInfo {
        endCursor
      }
      edges {
        node {
          id
          name
          email
          displayName
          completedCampaigns {
            campaignId
          }
          activeCampaigns {
            campaignId
          }
          exitedCampaigns {
            campaignId
          }
          eventsTotal
        }
      }
    }
  }
`);

export const GET_PEOPLE_FOR_MESSAGE_EVENT = graphql(`
  query GetPeopleForMessageEvent(
    $eventName: String!
    $campaignId: String!
    $actionId: String
    $cursor: String
    $limit: Int
  ) {
    peopleForMessageEvent(
      eventName: $eventName
      campaignId: $campaignId
      actionId: $actionId
      cursor: $cursor
      limit: $limit
    ) {
      total
      pageInfo {
        endCursor
      }
      edges {
        node {
          id
          name
          email
          displayName
        }
      }
    }
  }
`);

export const GET_ORGS = graphql(`
  query GetOrgs($query: String, $filter: String, $cursor: String, $limit: Int) {
    allOrgs(query: $query, filter: $filter, cursor: $cursor, limit: $limit) {
      total
      pageInfo {
        endCursor
      }
      edges {
        node {
          id
          name
          completedCampaigns {
            campaignId
          }
          activeCampaigns {
            campaignId
          }
          exitedCampaigns {
            campaignId
          }
        }
      }
    }
  }
`);

export const GET_FILTERED_PEOPLE = graphql(`
  query GetFilteredPeople($filter: String) {
    allPeople(filter: $filter) {
      total
      pageInfo {
        endCursor
      }
      edges {
        node {
          id
          name
          email
          displayName
        }
      }
    }
  }
`);
export const GET_FILTERED_ORGS = graphql(`
  query GetFilteredOrgs($filter: String) {
    allOrgs(filter: $filter) {
      total
      pageInfo {
        endCursor
      }
      edges {
        node {
          id
          name
        }
      }
    }
  }
`);
export const GET_PERSON = graphql(`
  query GetPerson($id: String!) {
    personById(id: $id) {
      ...personFragment
    }
  }
`);

export const GET_ORG = graphql(`
  query GetOrg($id: String!) {
    orgById(id: $id) {
      id
      name
      members {
        id
        name
        email
        displayName
      }
      attributes {
        id
        name
        aType
        source
        value
      }
      completedCampaigns {
        campaignId
        campaignName
        date
      }
      activeCampaigns {
        campaignId
        campaignName
        date
      }
      exitedCampaigns {
        campaignId
        campaignName
        date
      }
      currentLifecycleStage {
        id
        name
      }
      cohorts {
        id
        name
      }
      mostRecentHealthSummary {
        id
        body
      }
      updates {
        id
        updateSource
        updatedAt
      }
    }
  }
`);

export const GET_ALL_ATTRIBUTES = graphql(`
  query GetAllAttributes {
    allOrgAttributes {
      id
      name
      aType
      source
      target
      slug
    }
    allPersonAttributes {
      id
      name
      aType
      source
      target
      slug
    }
  }
`);

export const GET_CRM_INTEGRATIONS = graphql(`
  query GetCrmIntegrations {
    crmIntegrations {
      id
      crmType
      config
    }
  }
`);

export const GET_MESSAGING_INTEGRATIONS = graphql(`
  query GetMessagingIntegrations {
    messagingIntegrations {
      id
      messagingType
      config
    }
  }
`);

export const GET_EVENTS_INTEGRATIONS = graphql(`
  query GetEventsIntegrations {
    eventsIntegrations {
      id
      eventsType
      config
      auth
      refreshTask {
        ...periodicTask
      }
      personRefreshTask {
        ...periodicTask
      }
    }
  }
`);

export const GET_LOOPS_INTEGRATIONS = graphql(`
  query GetLoopsIntegrations {
    loopsIntegrations {
      id
      loopsType
      config
    }
  }
`);

export const GET_MESSAGING_SENDERS = graphql(`
  query GetMessagingSenders($integrationId: String) {
    messagingSenders(messagingIntegrationId: $integrationId) {
      id
      displayName
      externalId
    }
  }
`);

export const GET_USER_PROFILE = graphql(`
  query GetUserProfile {
    userProfile {
      id
      name
      apiToken
    }
  }
`);

export const GET_INCENTIVES = graphql(`
  query GetIncentives {
    incentives {
      id
      name
      type
      config
      cost
    }
  }
`);

export const GET_SESSION = graphql(`
  query GetSession {
    session {
      isImpersonation
      companyId
      companyName
      messagingType
      crmType
      isOnLifecycleStageAllowlist
      settings {
        passwordAuthDisabled
        canAccessReferrals
        referInbox
        isReferralsCustomer
        audienceAttributesToSummarize {
          target
          name
          source
        }
      }
    }
    userProfile {
      id
      name
      apiToken
    }
    allPersonAttributes {
      id
      name
      aType
      source
      target
      slug
      displayName
      visible
    }
    allOrgAttributes {
      id
      name
      aType
      source
      target
      slug
      displayName
      visible
    }
    featureFlags {
      id
      name
      enabledAt
    }
    allEvents
    allPeople(limit: 1) {
      total
    }
    allOrgs(limit: 1) {
      total
    }
    dashboardConfigs {
      id
      location
      definition
      createdAt
      updatedAt
    }
  }
`);

export const GET_TEAMMATES = graphql(`
  query GetTeammates {
    teammates {
      id
      name
      email
    }
  }
`);

export const GET_INVITES = graphql(`
  query GetInvites {
    invites {
      id
      email
      expires
    }
  }
`);

export const GET_VALUE_HINTS = graphql(`
  query GetValueHints(
    $attrTypeId: String!
    $target: AttributeTarget!
    $search: String
  ) {
    attributeValueHints(
      attrTypeId: $attrTypeId
      target: $target
      search: $search
    )
  }
`);

export const GET_SEGMENT_SOURCES = graphql(`
  query GetSegmentSources {
    segmentSources {
      id
      name
    }
  }
`);

export const GET_EVENT_SUMMARIES = graphql(`
  query GetAllEventSummaries($cursor: Int, $limit: Int) {
    allEventSummaries(cursor: $cursor, limit: $limit) {
      total
      edges {
        node {
          id
          eventName
          source
          totalCount
          firstSeen
          lastSeen
        }
      }
    }
  }
`);

export const GET_EVENT_STATS = graphql(`
  query GetEventStats($eventName: String!) {
    eventStats(eventName: $eventName) {
      count
      peopleCount
      timeSeries {
        x
        y
      }
    }
  }
`);

export const GET_PERSON_EVENT_HISTORY = graphql(`
  query GetPersonEventHistory($personId: String!, $eventName: String!) {
    personEventHistory(personId: $personId, eventName: $eventName) {
      person {
        displayName
      }
      edges {
        node {
          id
          happenedAt
          postedAt
          source
        }
      }
    }
  }
`);

export const GET_CAMPAIGN_MESSAGE_STATS = graphql(`
  query campaignMessageStats($campaignId: String!, $actionId: String) {
    campaignMessageStats(campaignId: $campaignId, actionId: $actionId) {
      id
      sent
      delivered
      open
      click
      bounce
      unsubscribe
    }
  }
`);

export const GET_CAMPAIGNS_TARGETING_MACHINE_INSTANCE_ID = graphql(`
  query GetCampaignsTargetingMachineInstanceId(
    $machineInstanceId: String!
    $cohortId: String
  ) {
    campaignsTargetingMachineInstanceId(
      machineInstanceId: $machineInstanceId
      cohortId: $cohortId
    ) {
      id
      name
      started
      campaignType
      targetMemberType
      status
    }
  }
`);

export const GET_CAMPAIGNS_BY_TARGETS = graphql(`
  query GetCampaignsByTargets(
    $behaviorId: String
    $cohortId: String
    $upsellScore: Boolean
  ) {
    campaignsByTargets(
      behaviorId: $behaviorId
      cohortId: $cohortId
      upsellScore: $upsellScore
    ) {
      id
      name
    }
  }
`);

export const GET_SLACK_CHANNELS = graphql(`
  query GetSlackChannels {
    slackChannels {
      id
      name
    }
    slackUsers {
      id
      name
      emailAddress
    }
  }
`);

export const GET_BEHAVIOR_MEMBERS = graphql(`
  query GetBehaviorMembers(
    $behaviorId: String!
    $cursor: String
    $limit: Int
    $stateFilter: String
    $memberFilter: JSONString
  ) {
    behaviorMembers(
      behaviorId: $behaviorId
      cursor: $cursor
      limit: $limit
      stateFilter: $stateFilter
      memberFilter: $memberFilter
    ) {
      total
      edges {
        node {
          id
          member {
            ... on PersonType {
              id
              name
              email
              displayName
              org {
                name
              }
            }

            ... on OrgType {
              id
              name
            }
          }
          entered
          completed
          attributes {
            ... on OrgAttribute {
              name
              displayName
              value
              source
            }
            ... on PersonAttribute {
              name
              displayName
              value
              source
            }
          }
        }
      }
    }
  }
`);

export const GET_CAMPAIGN_MEMBERS = graphql(`
  query GetCampaignMembers(
    $campaignId: String!
    $cursor: String
    $limit: Int
    $stateFilter: String
  ) {
    campaignMembers(
      campaignId: $campaignId
      cursor: $cursor
      limit: $limit
      stateFilter: $stateFilter
    ) {
      total
      edges {
        node {
          id
          member {
            ... on PersonType {
              id
              name
              email
              displayName
              org {
                name
              }
            }

            ... on OrgType {
              id
              name
            }
          }
          entered
          completed
          exited
        }
      }
    }
  }
`);

export const GET_COHORT_MEMBERS = graphql(`
  query GetCohortMembers(
    $cohortId: String!
    $cursor: String
    $limit: Int
    $stateFilter: String
  ) {
    cohortMembers(
      cohortId: $cohortId
      cursor: $cursor
      limit: $limit
      stateFilter: $stateFilter
    ) {
      total
      edges {
        node {
          id
          member {
            ... on PersonType {
              id
              name
              email
              displayName
              org {
                name
              }
            }

            ... on OrgType {
              id
              name
            }
          }
          lastEntered
          lastLeft
        }
      }
    }
  }
`);

export const GET_REFERRAL_MEMBERS = graphql(`
  query GetReferralMembers(
    $referralId: String!
    $filter: String
    $cursor: String
    $limit: Int
  ) {
    referralMembers(
      referralId: $referralId
      filter: $filter
      cursor: $cursor
      limit: $limit
    ) {
      total
      edges {
        node {
          id
          member {
            ... on PersonType {
              id
              name
              email
              displayName
              org {
                name
              }
            }

            ... on OrgType {
              id
              name
            }
          }
          referredAt
          referrer {
            name
            email
          }
          referee {
            name
            email
          }
        }
      }
    }
  }
`);

export const GET_SCHEDULED_SUMMARY = graphql(`
  query GetScheduledSummary($objectId: String!) {
    scheduledSummaryByObjectId(objectId: $objectId) {
      id
      objectIdToSummarize
      config {
        slack {
          channelId
        }
        statesToSummarize {
          stateName
          countPrefix
          listPrefix
          listItemTemplate
          includeCount
          includeList
        }
      }
      schedule {
        minute
        hour
        dayOfWeek
      }
    }
  }
`);

export const GET_SHOPIFY_PRODUCTS = graphql(`
  query GetShopifyProducts($first: Int, $queryString: String) {
    allShopifyProducts(first: $first, queryString: $queryString) {
      ...shopifyProductFragment
    }
  }
`);

export const GET_SHOPIFY_PRODUCT = graphql(`
  query GetShopifyProduct($id: String!) {
    shopifyProduct(id: $id) {
      ...shopifyProductFragment
    }
  }
`);

export const GET_SHOPIFY_CART_FOR_VARIANT = graphql(`
  query GetCartForProductVariant($id: String!) {
    shopifyCartForVariant(productVariantId: $id) {
      id
      checkoutUrl
      cost
      discountCodes
    }
  }
`);

export const GET_BEHAVIOR_STATS_FOR_MEMBER = graphql(`
  query GetBehaviorStatsForMember($memberId: String!) {
    behaviorStatsForMember(memberId: $memberId) {
      behaviorId
      behaviorName
      completed
      average
    }
  }
`);

export const GET_AI_FILTER_GROUPS = graphql(`
  query GetAiFilterGroups($query: String!) {
    aiFilterGroups(query: $query) {
      filters
      errors {
        snippet
        message
      }
    }
  }
`);

export const GET_LIFECYCLE_STAGES = graphql(`
  query GetLifecycleStages {
    allLifecycleStages {
      id
      name
      behavior {
        id
        name
        status
        targetMemberType
        audience
        observing
        actionsOnEnter
        actionsOnCompletion
        actionsWhileObserving
        actionsOnExit
        stats {
          completed {
            count
            timeSeries {
              x
              y
            }
          }
          entered {
            count
            timeSeries {
              x
              y
            }
          }
          observing {
            count
            timeSeries {
              x
              y
            }
          }
          timeToCompletion {
            average
            plot {
              x
              y
            }
          }
        }
      }
      objectives
    }
  }
`);

export const GET_LIFECYCLE_STAGES_WITH_OBJECTIVE_BEHAVIORS = graphql(`
  query GetLifecycleStagesWithObjectiveBehaviors {
    allLifecycleStages {
      ...lifecycleStageFragment
    }
  }
`);

export const GET_LIFECYCLE_STAGE_BY_ID = graphql(`
  query GetLifecycleStageById($id: String!) {
    lifecycleStageById(id: $id) {
      ...lifecycleStageFragment
      totalRevenueAtRisk
    }
  }
`);

export const GET_LIFECYCLE_STAGE_COHORT_RISK = graphql(`
  query GetLifecycleStageCohortRisk($id: String!) {
    lifecycleStageCohortRisk(id: $id) {
      objectiveId
      cohortId
      cohortName
      numberOfOrgs
      totalRevenue
    }
  }
`);

export const GET_AUDIENCE_ATTRIBUTE_SUMMARIES = graphql(`
  query GetAudienceAttributeSummaries(
    $filter: String
    $target: AttributeTarget!
    $attributesToSummarize: JSONString
  ) {
    audienceAttributeSummaries(
      filter: $filter
      target: $target
      attributesToSummarize: $attributesToSummarize
    ) {
      source
      name
      aType
      displayName
      countTotal
      countNullValues
      countUniqueValues {
        value
        count
      }
      average
    }
  }
`);

export const GET_ATTRIBUTES_FOR_CONTEXT = graphql(`
  query GetAttributesForContext($context: AttributeContexts) {
    attributesForContext(context: $context) {
      person {
        id
        name
        aType
        source
        slug
        target
        displayName
        visible
      }
      org {
        id
        name
        aType
        source
        slug
        target
        displayName
        visible
      }
    }
  }
`);

export const GET_MEMBER_TIMELINE = graphql(`
  query GetMemberTimelineByMemberId(
    $memberId: String!
    $targetMemberType: TargetMemberTypeChoices!
  ) {
    memberTimelineByMemberId(
      memberId: $memberId
      targetMemberType: $targetMemberType
    ) {
      memberId
      targetMemberType
      timelineItems {
        ... on MemberTimelineEventType {
          timestamp
          objectId
          objectType
          eventType
          name
        }
        ... on MemberTimelineSummaryContentType {
          timestamp
          objectId
          objectType
          body
        }
        ... on MemberTimelineEmailsSentType {
          timestamp
          objectId
          objectType
          sendingObject {
            name
            machineId
          }
          messagesSent {
            sender
            subject
            recipient
          }
        }
      }
    }
  }
`);

export const GET_INTERVENTIONS_FOR_LIFECYCLE_STAGE = graphql(`
  query GetInterventionsForLifecycleStage($lifecycleStageId: String!) {
    interventionsForLifecycleStage(lifecycleStageId: $lifecycleStageId) {
      id
      type
      name
      plan
      cohorts {
        ... on ObjectiveCohortRiskType {
          cohortId
          cohortName
          cohortServiceLevel
          numberOfOrgs
          totalRevenue
        }
        ... on CohortUpsellTargetType {
          cohortId
          cohortName
          cohortServiceLevel
          numberOfUpsellOrgs
          avgRevenue
        }
      }
      machineId
      lifecycleStageId
      actioned
      action
      supportingData
      createdAt
      updatedAt
    }
  }
`);
