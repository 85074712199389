import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ServerError,
  ApolloLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getCookie } from "./csrf";

const mergeConnection = function (existing, incoming) {
  if (!incoming) return existing;
  if (!existing) return incoming;
  const { edges, ...rest } = incoming;
  let result = rest;
  const existingEdges = existing.edges || [];
  const newEdges = edges || [];
  const existingEdgeIds = new Set<string | number>(
    existingEdges.map((e) => e.node.__ref),
  );
  const newNewEdges = newEdges.filter(
    (e) => !existingEdgeIds.has(e.node.__ref),
  );
  result.edges = [...existingEdges, ...newNewEdges];
  return result;
};

const httpLink = new HttpLink({ uri: "/graphql" });

const csrfMiddleWare = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "X-CSRFToken": getCookie("csrftoken"),
    },
  }));

  return forward(operation);
});

const redirectOn403 = onError(({ networkError }) => {
  if (networkError && (networkError as ServerError).statusCode === 403) {
    // TODO - include the current path so we can redirect to the right place
    window.location.replace(`/login?redirect=${window.location.pathname}`);
  }
});
const client = new ApolloClient({
  link: from([redirectOn403, csrfMiddleWare, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          allPeople: {
            keyArgs: ["filter"],
            merge: mergeConnection,
          },
          allOrgs: {
            keyArgs: ["query"],
            merge: mergeConnection,
          },
          allEventSummaries: {
            keyArgs: [],
            merge: mergeConnection,
          },
          peopleForMessageEvent: {
            keyArgs: ["eventName", "actionId", "campaignId"],
            merge: mergeConnection,
          },
          behaviorMembers: {
            keyArgs: ["behaviorId", "stateFilter"],
            merge: mergeConnection,
          },
          cohortMembers: {
            keyArgs: ["cohortId", "stateFilter"],
            merge: mergeConnection,
          },
          campaignMembers: {
            keyArgs: ["campaignId", "stateFilter"],
            merge: mergeConnection,
          },
          referralMembers: {
            keyArgs: ["referralId"],
            merge: mergeConnection,
          },
        },
      },
    },
  }),
  connectToDevTools: true,
});

export { client };
