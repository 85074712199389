export function getMessageFromSsoError(error: string) {
  const errorMessages = {
    sso_failed: "SSO authentication failed",
    account_not_found: "SSO login failed - Account not found",
    email_mismatch: "SSO signup failed - Email mismatch",
    user_already_exists: "SSO signup failed - User already exists",
    invite_already_claimed: "SSO signup failed - Invite already claimed",
    invite_does_not_exist: "SSO signup failed - Invite does not exist",
    invite_has_expired: "SSO signup failed - Invite has expired",
  };

  return (
    errorMessages[error] ||
    "SSO Authentication failed. Please try again or contact us for support."
  );
}
