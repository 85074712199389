import { useSearchParams } from "react-router-dom";
import PageHeader from "../patterns/PageHeader";
import TabNavigation from "../patterns/TabNavigation";
import { useMemo, useState } from "react";
import Teammates from "./Teammates";
import Invites from "./Invites";
import NewTeammate from "./NewTeammate";

export const VIEW_PARAM = "view";

enum Views {
  teammates = "teammates",
  invites = "invites",
}

export default function TeammatesMain() {
  let [searchParams, setSearchParams] = useSearchParams();
  let [newTeammateVisible, setNewTeammateVisible] = useState(false);
  let view = searchParams.get(VIEW_PARAM) ?? Views.teammates;
  const tabs = useMemo(
    () => [
      {
        label: "Teammates",
        action: () => setSearchParams(``),
        isActive: view === Views.teammates,
      },
      {
        label: "Invites",
        action: () => setSearchParams(`${VIEW_PARAM}=${Views.invites}`),
        isActive: view === Views.invites,
      },
    ],
    [setSearchParams, view],
  );
  return (
    <div className="flex flex-col max-w-8xl mx-auto mt-0">
      {newTeammateVisible && (
        <NewTeammate closeModal={() => setNewTeammateVisible(false)} />
      )}
      <PageHeader
        header={"Teammates"}
        subhead={""}
        buttons={[
          {
            label: "Invite teammate",
            type: "primary",
            action: () => {
              setNewTeammateVisible(true);
            },
          },
        ]}
      />
      <TabNavigation tabs={tabs} />
      {view === Views.teammates && <Teammates />}
      {view === Views.invites && (
        <Invites invite={() => setNewTeammateVisible(true)} />
      )}
    </div>
  );
}
