import { useNavigate } from "react-router-dom";
import { MachineCampaignType } from "../../__generated__/graphql";
import { titleCase } from "../../lib/string";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export default function CampaignRow({
  campaign,
}: {
  campaign: MachineCampaignType;
}) {
  const navigate = useNavigate();

  const campaignInDraft = campaign.status === "DRAFT";
  return (
    <div
      className="text-sm group"
      onClick={() => {
        navigate(`/campaigns/${campaign.id}`);
      }}
    >
      <div className="grid grid-cols-6 rounded-t-xl mb-0.5 bg-body-text/10 group-hover:bg-body-text/20 transition-colors duration-500 p-3 font-bold">
        <div className="col-span-2 flex flex-row items-center gap-1">
          Campaign Name <ArrowTopRightOnSquareIcon className="w-3 h-3" />
        </div>
        <div className="col-start-3">Status</div>
        <div className="col-start-4">Entered</div>
        <div className="col-start-5">Completed</div>
        <div className="col-start-6">Avg time to complete</div>
      </div>
      <div className="grid grid-cols-6 rounded-b-xl bg-body-text/10 group-hover:bg-body-text/20 transition-colors duration-500 p-3">
        <div className="col-span-2 row-start-2">{campaign.name}</div>
        <div className="col-start-3 row-start-2">
          <span className="px-2.5 py-[1px] rounded-full body-text-light border-body-text-lightest border-1 inline-flex items-center">
            <span
              style={{
                width: 7,
                height: 7,
                display: "inline-block",
                borderRadius: "50%",
              }}
              className="border-1 border-body-text-light mr-1.5"
            ></span>
            {titleCase(campaign.status)}
          </span>
        </div>
        <div className="col-start-4 row-start-2">
          {campaignInDraft ? "-" : campaign.stats.entered.count}
        </div>
        <div className="col-start-5 row-start-2">
          {campaignInDraft ? "-" : campaign.stats.completed.count}
        </div>
        <div className="col-start-6 row-start-2">
          {campaignInDraft
            ? "-"
            : Math.round(campaign.stats.timeToCompletion.average) + " days"}
        </div>
      </div>
    </div>
  );
}
