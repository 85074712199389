import { useCallback, useEffect, useMemo, useState } from "react";
import { meanBy } from "lodash";
import toast from "react-hot-toast";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { SparklesIcon } from "@heroicons/react/24/outline";
import {
  ExtendedInterventionType,
  CohortUpsellTargetType,
} from "../../__generated__/graphql";
import { GENERATE_PLAN_FOR_UPSELL_COHORT } from "../../graphql/mutations";
import { GET_CAMPAIGN, GET_CAMPAIGNS_BY_TARGETS } from "../../graphql/queries";
import { InterventionTypes } from "../lib";
import StatsHeading from "../../patterns/StatsHeading";
import EntryHeaderWrapper from "./EntryHeaderWrapper";
import CampaignRow from "./CampaignRow";

export default function UpsellCohort({
  intervention,
  isSelected,
}: {
  intervention: ExtendedInterventionType;
  isSelected: boolean;
}) {
  const cohortTargets = intervention.cohorts;
  const cohort = cohortTargets[0];

  const [plan, setPlan] = useState({
    campaign: null,
  });
  const [planLoading, setPlanLoading] = useState(false);
  const { data, loading } = useQuery(GET_CAMPAIGNS_BY_TARGETS, {
    variables: {
      cohortId: cohort.cohortId,
      upsellScore: true,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data?.campaignsByTargets.length > 0) {
      setPlan({
        campaign: data.campaignsByTargets[0],
      });
    }
  }, [data?.campaignsByTargets]);

  const totalOrgs = cohortTargets.reduce(
    (acc: number, target: CohortUpsellTargetType) =>
      acc + target.numberOfUpsellOrgs,
    0,
  );

  const {
    avg_revenue: avgRevenue,
    potential_avg_revenue: potentialAvgRevenue,
    org_performance_breakdown: orgPerformanceBreakdown,
  } = JSON.parse(intervention.supportingData);

  const totalPotentialRevenue = (potentialAvgRevenue - avgRevenue) * totalOrgs;
  const avgFocusPercentileScore = meanBy(
    orgPerformanceBreakdown,
    "focus_score",
  );

  const entryDescription = useMemo(() => {
    return `${totalOrgs} customers in the ${cohort.cohortName} cohort are exhibiting high levels of engagement`;
  }, [totalOrgs, cohort.cohortName]);

  const [generatePlanForObjectiveCohort] = useMutation(
    GENERATE_PLAN_FOR_UPSELL_COHORT,
    { variables: { cohortId: cohort.cohortId } },
  );

  const handleGeneratePlan = useCallback(async () => {
    try {
      setPlanLoading(true);
      const result = await generatePlanForObjectiveCohort({
        variables: {
          cohortId: cohort.cohortId,
        },
      });
      if (result.data.generatePlanForUpsellCohort.ok) {
        toast.success("Plan generated successfully enabled");
        setPlan({
          campaign: result.data.generatePlanForUpsellCohort.campaign,
        });
      }
    } catch (e) {
      toast.error(e.message);
    }
    setPlanLoading(false);
  }, [cohort.cohortId, generatePlanForObjectiveCohort]);

  const [
    getFullCampaignData,
    { data: campaignData, loading: campaignLoading },
  ] = useLazyQuery(GET_CAMPAIGN);

  useEffect(() => {
    if (plan.campaign) {
      getFullCampaignData({ variables: { id: plan.campaign.id } });
    }
  }, [plan.campaign, getFullCampaignData]);

  const fullCampaign = useMemo(() => {
    if (!campaignData) return null;
    return campaignData.campaignById;
  }, [campaignData]);

  return (
    <EntryHeaderWrapper
      name={intervention.name}
      description={entryDescription}
      circleFill={(avgRevenue / potentialAvgRevenue) * 100}
      totalRevenue={totalPotentialRevenue}
      isSelected={isSelected}
      type={InterventionTypes.UpsellCohort}
    >
      {plan.campaign ? (
        <div className="ml-16">
          {(loading || campaignLoading) && <div className="spinner" />}
          {fullCampaign && (
            <>
              <div className="my-4 text-md border-body-text-lightest">
                You have an existing Campaign targeting this Cohort Upsell
                Opportunity:
              </div>
              <CampaignRow campaign={fullCampaign} />
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col pb-4 ml-16">
          <div className="mt-6 mb-4 max-w-screen-sm wrap-balance">
            We have identified the potential to increase average revenue from{" "}
            <span className="font-medium">
              ${Math.round(avgRevenue).toLocaleString()}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              ${Math.round(potentialAvgRevenue).toLocaleString()}
            </span>{" "}
            for the identified organizations, representing a total revenue
            opportunity of{" "}
            <span className="font-medium">
              ${Math.round(totalPotentialRevenue).toLocaleString()}
            </span>
          </div>
          <span className="text-md font-bold tracking-tight block mb-2">
            Engagement overview:
          </span>
          <div className="flex flex-row text-sm mb-4 w-full gap-0.5 rounded-xl overflow-hidden">
            <div className="bg-body-text/10 p-4 w-full flex">
              <StatsHeading
                title="Avg lifecycle stage engagement:"
                stat={"High"}
              />
            </div>
            <div className="bg-body-text/10 p-4 w-full flex">
              <StatsHeading
                title="Avg event completion percentile"
                stat={`${avgFocusPercentileScore.toFixed(2)}%`}
              />
            </div>
          </div>
          <span className="text-md mt-2 font-bold tracking-tight block mb-2">
            Organization engagement:
          </span>
          <div className="grid grid-cols-3 gap-0.5 mb-4 text-sm">
            <div className="col-span-3 grid grid-cols-3 bg-body-text/10 p-3 rounded-t-xl font-bold">
              <div>Organization Name</div>
              <div>Contract Value</div>
              <div>Account Manager</div>
            </div>
            {orgPerformanceBreakdown.map((org) => (
              <div className="col-span-3 grid grid-cols-3 p-3 bg-body-text/10 last:rounded-b-xl">
                <div>{org.org_name}</div>
                <div>
                  {org.revenue &&
                    `$${Math.trunc(org.revenue).toLocaleString()}`}
                </div>
                <div>{org.account_manager ? org.account_manager : "N/A"}</div>
              </div>
            ))}
          </div>
          <div className="flex flex-row items-center">
            <span className="text-md mt-2 font-bold tracking-tight block">
              Would you like me to create a campaign that upsells these
              customers?
            </span>
          </div>
          <div className="flex flex-row items-center my-4 text-xs gap-2">
            {planLoading ? (
              <div className="flex flex-row btn-md animate border border-body-text text-body-text gap-2 hover:bg-body-text hover:text-white items-center">
                Generating plan
                <div className="w-4 h-4">
                  <div className="spinner"></div>
                </div>
              </div>
            ) : (
              <>
                <button
                  type="button"
                  className="flex flex-row btn-md animate border border-body-text text-body-text gap-1.5 hover:bg-body-text hover:text-white items-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleGeneratePlan();
                  }}
                >
                  Create a Campaign
                  <div className="w-5 h-5">
                    <SparklesIcon />
                  </div>
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </EntryHeaderWrapper>
  );
}
