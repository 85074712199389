import StatsHeading from "../../patterns/StatsHeading";

export default function RevenueImpact({
  totalRevenue,
  label = "Impacted Revenue",
}: {
  totalRevenue: number;
  label?: string;
}) {
  return (
    <>
      <div className="flex flex-col -mt-1 text-right">
        <StatsHeading
          title={label}
          stat={totalRevenue.toLocaleString()}
          symbol="$"
        />
      </div>
    </>
  );
}
