import { useParams } from "react-router-dom";
import PersonProfile from "./PersonProfile";

export default function PersonMain() {
  const params = useParams();

  return (
    <>
      <div className="max-w-8xl mx-auto mt-0">
        <PersonProfile id={params.id} renderBreadcrumbs={true} />
      </div>
    </>
  );
}
