import { useMemo, useCallback, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  AttributeContexts,
  AttributeTarget,
  BehaviorType,
  Flags,
  LifecycleStageType,
  OrgType,
  PersonType,
} from "../__generated__/graphql";

import { Tabs } from "./lib";
import {
  GET_BEHAVIORS_BY_ID,
  GET_INTERVENTIONS_FOR_LIFECYCLE_STAGE,
} from "../graphql/queries";
import { CREATE_CAMPAIGN } from "../graphql/mutations";
import FilterBuilder from "../filter_builder";
import useQueryAndFilter from "../hooks/useQueryAndFilter";
import SkeletonScreen from "../patterns/Skeleton";
import FullScreenError from "../patterns/Errors";
import PageHeader from "../patterns/PageHeader";
import OrgProfile from "../org/OrgProfile";
import Drawer from "../patterns/Drawer";
import AudienceAttributeSummaries from "../patterns/AudienceAttributeSummaries";
import PersonProfile from "../person/PersonProfile";
import TabsAndGraph from "./overview/TabsAndGraph";
import { MemberTable } from "./overview/MemberTable";
import Objective from "./overview/Objective";
import {
  FilterBuilderContext,
  FilterBuilderContextType,
  getFilterBuilderContext,
} from "../FilterBuilderContext";
import useIsFeatureEnabled from "../hooks/useIsFeatureEnabled";
import InterventionList from "./interventions/InterventionList";

export default function LifecycleStageOverview({
  lifecycleStage,
}: {
  lifecycleStage: LifecycleStageType;
}) {
  const canViewInterventions = useIsFeatureEnabled(Flags.CanViewInterventions);

  const navigate = useNavigate();
  const [createCampaignMutation] = useMutation(CREATE_CAMPAIGN);
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Eligible);
  const [activeMember, setActiveMember] = useState<PersonType | OrgType>();
  const { filter, updateFilter } = useQueryAndFilter();
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const closeDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);

  const filterBuilderContext: FilterBuilderContextType = useMemo(() => {
    return getFilterBuilderContext(AttributeContexts.LifecycleOverview);
  }, []);

  const behavior = lifecycleStage.behavior;

  const parsedObjectives = useMemo(() => {
    return JSON.parse(lifecycleStage.objectives) || [];
  }, [lifecycleStage.objectives]);

  const relevantBehaviorIds = useMemo(() => {
    return parsedObjectives.flatMap((behavior) => [
      behavior.id,
      ...behavior.sub_behavior_ids,
    ]);
  }, [parsedObjectives]);

  const { data: interventions, loading: interventionsLoading } = useQuery(
    GET_INTERVENTIONS_FOR_LIFECYCLE_STAGE,
    {
      variables: { lifecycleStageId: lifecycleStage.id },
    },
  );

  const interventionData = interventions?.interventionsForLifecycleStage;

  const { data, loading, error } = useQuery(GET_BEHAVIORS_BY_ID, {
    variables: { ids: relevantBehaviorIds },
  });

  const objectiveBehaviors = useMemo(() => {
    if (!loading) {
      return parsedObjectives.map((objective) => {
        return {
          behavior: data?.behaviorsById.find((b) => b.id === objective.id),
          subBehaviors: objective.sub_behavior_ids.map((id) =>
            data?.behaviorsById.find((b) => b.id === id),
          ),
        };
      });
    }
  }, [parsedObjectives, data, loading]);

  const createCampaignFromBehavior = useCallback(
    async (behavior: BehaviorType) => {
      try {
        const result = await createCampaignMutation({
          variables: {
            name: `Incentivize ${behavior.name}`,
            targetMemberType: behavior.targetMemberType,
            fromBehaviorId: behavior.id,
          },
        });
        if (result.data.createCampaign.ok) {
          navigate(
            `/campaigns/${result.data.createCampaign.campaign.id}?view=settings&from=${AttributeContexts.ObjectiveCampaign}`,
          );
        }
      } catch (e) {
        toast.error(e.message);
      }
    },
    [createCampaignMutation, navigate],
  );

  const attributeSummaryFilter = useMemo(() => {
    return {
      version: "1",
      filters: {
        operator: "AND",
        conditions: [
          {
            source: "machine_metadata",
            path: `member::${behavior?.id}`,
            target: AttributeTarget.Org,
            operator: "NOT_NULL",
          },
        ],
      },
    };
  }, [behavior]);

  const attributesToSummarize = [
    {
      name: "Onboarding_rag_status",
      source: "lifecycle_stage",
      target: "org",
      roll_up: {
        type: "count",
      },
    },
  ];

  if (!behavior) {
    return (
      <div className="grid py-24 items-center text-center">
        Lifecycle Stage: {lifecycleStage.name} has no associated Behavior
      </div>
    );
  }

  if (loading) return <SkeletonScreen />;
  if (error) return <FullScreenError />;

  const hasObjectives = objectiveBehaviors?.length > 0;
  const hasInterventions = interventionData?.length > 0;

  return (
    <>
      {drawerIsVisible && activeMember && (
        <Drawer close={closeDrawer}>
          <div className="mt-10">
            {activeMember.__typename === "PersonType" ? (
              <PersonProfile id={activeMember.id} />
            ) : (
              <OrgProfile id={activeMember.id} />
            )}
          </div>
        </Drawer>
      )}
      {canViewInterventions && (
        <>
          {interventionsLoading ? (
            <SkeletonScreen label="Talking to AI Agents..." />
          ) : (
            <>
              {hasInterventions && (
                <InterventionList
                  lifecycleStage={lifecycleStage}
                  interventionData={interventionData}
                  objectives={objectiveBehaviors}
                />
              )}
            </>
          )}
        </>
      )}
      {canViewInterventions && hasInterventions && (
        <PageHeader header="Customer performance" subhead="" />
      )}
      <TabsAndGraph behavior={behavior} setActiveTab={setActiveTab} />
      <MemberTable
        behavior={behavior}
        activeTab={activeTab}
        showMember={(member) => {
          setActiveMember(member);
          setDrawerIsVisible(true);
        }}
        memberFilter={filter}
        filterBar={
          <div className="flex space-x-2">
            <div className="grow">
              <FilterBuilderContext.Provider value={filterBuilderContext}>
                <FilterBuilder
                  showOrgAttrs={true}
                  showPersonAttrs={false}
                  filterDoc={filter}
                  onChange={updateFilter}
                />
              </FilterBuilderContext.Provider>
            </div>
            <div className="min-w-64">
              <div className="flex flex-row items-center align-middle text-sm justify-end">
                <AudienceAttributeSummaries
                  filter={attributeSummaryFilter}
                  attributeTarget={AttributeTarget.Org}
                  attributesToSummarize={attributesToSummarize}
                  orientation="horizontal"
                  onClick={(attrFilterObj) => {
                    updateFilter(attrFilterObj);
                  }}
                />
              </div>
            </div>
          </div>
        }
      />
      {hasObjectives && (
        <>
          <PageHeader
            header={`${lifecycleStage.name} Objectives`}
            subhead=""
            buttons={[
              {
                type: "primary",
                label: "Add an Objective",
                action: () => {
                  navigate("./objectives/new");
                },
              },
            ]}
          />
          <div className="px-8">
            {objectiveBehaviors.map(({ behavior, subBehaviors }) => (
              <Objective
                key={behavior.id}
                lifecycleStageId={lifecycleStage.id}
                behavior={behavior}
                subBehaviors={subBehaviors}
                onCreateCampaign={createCampaignFromBehavior}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
}
