import { ArrowUpIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import CampaignsIcon from "../patterns/symbols/CampaignsIcon";
import PeopleIcon from "../patterns/symbols/PeopleIcon";
import SlackLogo from "../patterns/symbols/SlackLogo";
import FluxIcon from "../patterns/symbols/FluxIcon";

export enum Tabs {
  Eligible = "eligible",
  Completed = "completed",
  TimeToCompletion = "timeToCompletion",
  Observing = "observing",
}

export enum InterventionTypes {
  IncentiviseObjective = "incentivise_objective",
  ImproveCampaignEngagement = "improve_campaign_engagement",
  ImproveCampaignIncentive = "improve_campaign_incentive",
  UpsellCohort = "upsell_cohort",
}

export enum ServiceLevels {
  MidMarket = "mid_market",
  SelfServe = "self_serve",
  Enterprise = "enterprise",
}

interface ServiceLevelAction {
  name: string;
  icon: React.ReactNode;
}

export const SERVICE_LEVEL_ACTIONS = {
  [ServiceLevels.SelfServe]: (cohortName: string): ServiceLevelAction[] => [
    {
      name: `Target cohort: ${cohortName}`,
      icon: <PeopleIcon />,
    },
    {
      name: "Generate Campaign Email",
      icon: <CampaignsIcon strokeColor="white" />,
    },
    {
      name: "Generate Follow Up Email (7 days)",
      icon: <CampaignsIcon strokeColor="white" />,
    },
  ],
  [ServiceLevels.MidMarket]: (cohortName: string): ServiceLevelAction[] => [
    {
      name: `Target cohort: ${cohortName}`,
      icon: <PeopleIcon />,
    },
    {
      name: "Generate Campaign",
      icon: <CampaignsIcon strokeColor="white" />,
    },
  ],
  [ServiceLevels.Enterprise]: (cohortName: string): ServiceLevelAction[] => [
    {
      name: `Target cohort: ${cohortName}`,
      icon: <PeopleIcon />,
    },
    {
      name: "Notify the team via Slack",
      icon: <SlackLogo />,
    },
  ],
};

export const interventionTypeIconMap = {
  [InterventionTypes.IncentiviseObjective]: <ExclamationTriangleIcon />,
  [InterventionTypes.ImproveCampaignEngagement]: (
    <ChatBubbleOvalLeftEllipsisIcon />
  ),
  [InterventionTypes.UpsellCohort]: <ArrowUpIcon />,
  [InterventionTypes.ImproveCampaignIncentive]: <FluxIcon />,
};
