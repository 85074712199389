import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  BehaviorType,
  PersonType,
  OrgType,
  BehaviorStateNames,
  LifecycleStageType,
} from "../../__generated__/graphql";
import { GET_BEHAVIOR_MEMBERS } from "../../graphql/queries";
import { formatLong } from "../../lib/date";
import Table, { TableProps } from "../../patterns/tables/table";
import SkeletonScreen from "../../patterns/Skeleton";
import EngagementPill from "../../patterns/EngagementPill";

enum States {
  Entered = "entered",
  Observing = "observing",
  Completed = "completed",
  TimeToCompletion = "timeToCompletion",
}

interface OrgNode {
  id: string;
  completed: string;
  entered: string;
  observing: string;
  member: PersonType;
}

interface HeaderData {
  label: string;
}

interface CellData {
  content: string | React.ReactNode;
}

const HEADERS = {
  [States.Entered]: (stageName: string): HeaderData[] => [
    { label: "Name" },
    { label: `${stageName} Engagement` },
    { label: "Entered" },
  ],
  [States.Observing]: (stageName: string): HeaderData[] => [
    { label: "Name" },
    { label: `${stageName} Engagement` },
    { label: "Entered" },
  ],
  [States.Completed]: (stageName: string): HeaderData[] => [
    { label: "Name" },
    { label: `${stageName} Engagement` },
    { label: "Completed" },
  ],
  [States.TimeToCompletion]: (stageName: string): HeaderData[] => [
    { label: "Name" },
    { label: `${stageName} Engagement` },
    { label: "Completed" },
  ],
};

const CELLS = {
  [States.Entered]: (n: OrgNode, stageName: string): CellData[] => [
    { content: n.member.name },
    { content: stageRagStatus(n, stageName) },
    { content: formatLong(n.entered) },
  ],
  [States.Observing]: (n: OrgNode, stageName: string): CellData[] => [
    { content: n.member.name },
    { content: stageRagStatus(n, stageName) },
    { content: formatLong(n.entered) },
  ],
  [States.Completed]: (n: OrgNode, stageName: string): CellData[] => [
    { content: n.member.name },
    { content: stageRagStatus(n, stageName) },
    { content: formatLong(n.completed) },
  ],
  [States.TimeToCompletion]: (n: OrgNode, stageName: string): CellData[] => [
    { content: n.member.name },
    { content: stageRagStatus(n, stageName) },
    { content: formatLong(n.completed) },
  ],
};

const QUERY_MAP = {
  [States.Entered]: {
    variables: (behavior) => ({ behaviorId: behavior.id, limit: 10 }),
  },
  [States.Observing]: {
    variables: (behavior) => ({
      behaviorId: behavior.id,
      stateFilter: BehaviorStateNames.Observing,
      limit: 10,
    }),
  },
  [States.Completed]: {
    variables: (behavior) => ({
      behaviorId: behavior.id,
      stateFilter: BehaviorStateNames.Completed,
      limit: 10,
    }),
  },
  [States.TimeToCompletion]: {
    variables: (behavior) => ({
      behaviorId: behavior.id,
      stateFilter: BehaviorStateNames.Completed,
      limit: 10,
    }),
  },
};

function stageRagStatus(node: any, stageName: string) {
  const attrName = `${stageName}_rag_status`;
  const rag_value =
    node.attributes
      ?.find((attr) => attr.name === attrName)
      ?.value?.toLowerCase()
      .replace(/"/g, "") || "N/A";

  return EngagementPill({ status: rag_value });
}

const PAGE_SIZE = 10;

function toTable(
  stageName: string,
  data: any,
  activeState: any,
  showMember: (p: PersonType) => void,
): Omit<TableProps, "filterBar"> {
  return {
    header: HEADERS[activeState](stageName),
    rows: data.edges.map((p) => {
      return {
        id: p.node.id,
        onClick: (e: React.MouseEvent, id: string) => {
          showMember(p.node.member);
          e.stopPropagation();
        },
        cells: CELLS[activeState](p.node, stageName),
      };
    }),
  };
}

interface Props {
  lifecycleStage: LifecycleStageType;
  behavior: BehaviorType;
  activeState: "entered" | "observing" | "completed" | "timeToCompletion";
  showMember: (m: PersonType | OrgType) => void;
}

export default function MemberTable({
  lifecycleStage,
  behavior,
  activeState,
  showMember,
}: Props) {
  const [isPaginating, setIsPaginating] = useState(false);
  const query = QUERY_MAP[activeState];
  const { data, loading, fetchMore, refetch } = useQuery(GET_BEHAVIOR_MEMBERS, {
    variables: query.variables(behavior),
  });

  useEffect(() => {
    refetch({ behaviorId: behavior.id });
  }, [behavior, refetch]);

  const loadNext = useCallback(async () => {
    setIsPaginating(true);
    try {
      const key = Object.keys(data)[0];
      await fetchMore({
        variables: {
          cursor: data[key].edges[data[key].edges.length - 1].node.id,
          limit: PAGE_SIZE,
        },
      });
    } finally {
      setIsPaginating(false);
    }
  }, [data, fetchMore]);

  if (loading) {
    return <SkeletonScreen />;
  }

  const key = Object.keys(data)[0];
  const hasMore = data[key].total > data[key].edges.length;
  const tableData = toTable(
    lifecycleStage.name,
    data[key],
    activeState,
    showMember,
  );
  return (
    <>
      {tableData.rows.length !== 0 && !loading && (
        <>
          <Table header={tableData.header} rows={tableData.rows} />
          {isPaginating ? (
            <div className="w-full flex justify-center mb-4">
              <div className="spinner"></div>
            </div>
          ) : (
            <>
              {hasMore && (
                <div
                  className="-mt-4 mb-4 mx-auto text-sm text-center cursor-pointer text-body-text px-4 py-1.5 bg-pavlov-bg hover:bg-pavlov-bg-darker rounded-full animate"
                  onClick={loadNext}
                >
                  Show More +
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
