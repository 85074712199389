import React from "react";
import { useState } from "react";
import Line from "../patterns/charts/Line";
import { cx } from "../lib/cx";
import {
  MachineCampaignType,
  TargetMemberTypeChoices,
} from "../__generated__/graphql";
import { Tabs } from "./lib";
import CampaignEmpty from "../patterns/illustrations/CampaignEmpty";
import EmptyState from "../patterns/EmptyState";
import StatsHeading from "../patterns/StatsHeading";
import { titleCase } from "../lib/string";
import { iconMap } from "../patterns/StatsHeading";

interface Props {
  campaign: MachineCampaignType;
  maxAudienceSize: number;
  setActiveTab: (t: Tabs) => void;
}

export const CampaignOverview = ({
  campaign,
  setActiveTab,
  maxAudienceSize,
}: Props) => {
  const [currentTab, setCurrentTab] = useState(0);
  const entityName = campaign.isBehavior ? "behavior" : "campaign";
  const entered = campaign.stats.entered.count;
  const completed = campaign.stats.completed.count;
  const exited = campaign.stats.exited.count;
  const averageCompletion = Math.round(
    campaign.stats.timeToCompletion.average / 86400,
  );
  const enteredPercentage =
    maxAudienceSize && Math.round((entered / maxAudienceSize) * 100);
  const completedPercentage =
    entered && Math.round((completed / entered) * 100);
  const exitedPercentage = entered && Math.round((exited / entered) * 100);
  const campaignTarget =
    campaign.targetMemberType === TargetMemberTypeChoices.Org
      ? "organizations"
      : "people";

  const defaultEmptyStateDescription = `Your ${entityName} hasn't been sent to anyone yet.`;
  const dataPoints = [
    {
      id: 0,
      title: `Total ${campaignTarget} entered`,
      value: entered,
      percentage: enteredPercentage
        ? `${enteredPercentage}% of all ${campaignTarget}`
        : "-",
      current: true,
      tab: Tabs.Eligible,
      emptyStateDescription: defaultEmptyStateDescription,
    },
    {
      id: 1,
      title: `Total ${campaignTarget} completed`,
      value: completed,
      percentage: completedPercentage
        ? `${completedPercentage}% of audience`
        : "-",
      current: false,
      tab: Tabs.Completed,
      emptyStateDescription: defaultEmptyStateDescription,
    },
    {
      id: 2,
      title: "Average time to completion",
      value: `${averageCompletion}`,
      percentage: "Days",
      current: false,
      tab: Tabs.TimeToCompletion,
      emptyStateDescription: defaultEmptyStateDescription,
    },
    {
      id: 3,
      title: "Total exited without incentive",
      value: exited,
      percentage: exitedPercentage ? `${exitedPercentage}% of audience` : "-",
      current: false,
      tab: Tabs.Exited,
      emptyStateDescription: `${titleCase(campaignTarget)} that have exited the ${entityName} will show up here.`,
    },
  ];
  const graphdata = {
    0: {
      data: [{ id: 1, data: campaign.stats.entered.timeSeries }],
      type: "time",
    },
    1: {
      data: [{ id: 1, data: campaign.stats.completed.timeSeries }],
      type: "time",
    },
    2: {
      data: [{ id: 1, data: campaign.stats.timeToCompletion.plot }],
      type: "linear",
      valueSuffix: "day",
      markers: [
        {
          axis: "x",
          legend: "Average",
          lineStyle: {
            stroke: "#8D67FC",
            strokeWidth: 2,
            strokeDasharray: 8,
          } as Partial<React.CSSProperties>,
          textStyle: {
            fontSize: 12,
            fill: "#1E293B",
          } as Partial<React.CSSProperties>,
          value: averageCompletion,
        },
      ],
    },
    3: {
      data: [{ id: 1, data: campaign.stats.exited.timeSeries }],
      type: "time",
    },
  };
  const hasData = !!graphdata[currentTab].data[0].data.length;

  return (
    <div className="flex flex-col rounded-xl mx-8 mb-6">
      <div className="grid grid-cols-4 rounded-t-xl overflow-hidden">
        {dataPoints.map((item) => (
          <div
            className={cx(
              currentTab === item.id
                ? "bg-pavlov-bg"
                : "animate bg-pavlov-bg-lighter hover:bg-pavlov-bg-dark",
              "flex cursor-pointer flex-col border-r-1 border-white p-4 last:border-r-0",
            )}
            key={item.id}
            onClick={() => {
              setCurrentTab(item.id);
              setActiveTab(item.tab);
            }}
          >
            <StatsHeading
              title={item.title}
              stat={item.value}
              subtitle={item.percentage}
              icon={item.title as keyof typeof iconMap} // Type assertion
            />
          </div>
        ))}
      </div>
      <div className="h-96 w-auto bg-pavlov-bg px-4 py-4 rounded-b-lg">
        {hasData ? (
          <Line
            data={graphdata[currentTab].data}
            type={graphdata[currentTab].type}
            valueSuffix={graphdata[currentTab].valueSuffix}
            markers={graphdata[currentTab].markers}
          />
        ) : (
          <EmptyState
            title="No data"
            description={dataPoints[currentTab].emptyStateDescription}
            icon={<CampaignEmpty />}
            fullHeight={false}
          ></EmptyState>
        )}
      </div>
    </div>
  );
};
