import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import { fetchCSRFHeader } from "../lib/csrf";
import { getMessageFromSsoError } from "../lib/login";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import TextInput from "../patterns/forms/TextInput";
import PavlovLogoBlack from "../patterns/symbols/PavlovLogoBlack";
import LoginBG from "../patterns/illustrations/LoginBG";
import GoogleSSOButton from "../patterns/atoms/GoogleSSOButton";
import "../login.css";

const LOGIN_URL = "/app/login";
const SSO_LOGIN_URL = "/app/sso_login";

export default function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [ssoError, setSsoError] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const redirect = searchParams.get("redirect");

  const handleStandardLogin = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoggingIn(true);

      let formData = new FormData();
      formData.append("username", email);
      formData.append("password", password);

      const csrfToken = await fetchCSRFHeader();
      const response = await fetch(LOGIN_URL, {
        method: "POST",
        body: formData,
        headers: new Headers([["X-CSRFToken", csrfToken]]),
      });

      if (response.status === 200) {
        navigate(redirect || "/");
        window.location.reload();
      } else {
        const errorText = await response.text();
        toast.error(errorText ? errorText : "Login failed");
      }

      setIsLoggingIn(false);
    },
    [email, password, redirect, navigate],
  );

  const formRef = useRef<HTMLFormElement>();
  const submitForm = useCallback(() => {
    formRef.current.requestSubmit();
  }, [formRef]);

  const submitOnEnter = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        submitForm();
      }
    },
    [submitForm],
  );

  const handleSsoLogin = useCallback(async (e: any) => {
    e.preventDefault();
    setIsLoggingIn(true);
    const csrfToken = await fetchCSRFHeader();
    const body = new FormData();

    body.append("provider", "google");

    const response = await fetch(SSO_LOGIN_URL, {
      method: "POST",
      body,
      headers: new Headers([["X-CSRFToken", csrfToken]]),
    });

    if (response.status === 200) {
      const url = await response.text();
      window.location.replace(url);
    } else {
      toast("SSO Login failed");
    }
    setIsLoggingIn(false);
  }, []);

  useEffect(() => {
    const ssoErrorParam = searchParams.get("ssoError");
    if (ssoErrorParam) {
      setSsoError(getMessageFromSsoError(ssoErrorParam));
    }
  }, [searchParams]);

  return (
    <>
      <div className="absolute top-10 left-10 w-32">
        <PavlovLogoBlack />
      </div>

      <div className="flex items-center align-middle">
        <div className="w-full sm:w-4/5 md:w-3/5 xl:w-2/5">
          <div className="w-96 lg:w-[480px] mx-auto p-8">
            <form className="pb-4" ref={formRef} onSubmit={handleStandardLogin}>
              <div className="text-xl leading-9 tracking-tight font-bold">
                Welcome back!
              </div>
              <div className="text-sm mb-6 font-normal">
                Let's drive customer value
              </div>
              <TextInput
                label="Email"
                placeholder="Enter your email"
                size="large"
                value={email}
                required={true}
                onChange={setEmail}
                onKeyDown={submitOnEnter}
              />
              <TextInput
                type="password"
                label="Password"
                size="large"
                placeholder="Password"
                value={password}
                required={true}
                onChange={setPassword}
                onKeyDown={submitOnEnter}
              />
              <PrimaryButton
                label="Sign in to Pavlov"
                size="large"
                isDisabled={isLoggingIn}
                onClick={submitForm}
                fullWidth={true}
              />
            </form>
            <GoogleSSOButton
              onClick={handleSsoLogin}
              label={"Sign in with Google"}
            />
            {ssoError && (
              <div className="flex flex-row justify-center text-xs font-normal text-red-500">
                {ssoError}
              </div>
            )}
            <div className="text-xs font-normal pt-4">
              Forgot your password? Contact us.
            </div>
          </div>
        </div>

        <div className="ambient full-h w-0 sm:w-1/5 md:w-2/5 xl:w-3/5 h-full overflow-hidden">
          <LoginBG />
        </div>
      </div>
    </>
  );
}
