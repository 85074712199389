import { useMemo, useState, useCallback, useReducer, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-hot-toast";
import { GET_COHORT } from "../graphql/queries";
import { UPDATE_COHORT } from "../graphql/mutations";
import {
  PersonType,
  OrgType,
  TargetMemberTypeChoices,
  MachineStatusChoices,
} from "../__generated__/graphql";
import Drawer from "../patterns/Drawer";
import DashboardIcon from "../patterns/symbols/DashboardIcon";
import SkeletonScreen from "../patterns/Skeleton";
import FullScreenError from "../patterns/Errors";
import CampaignStatusPill from "../campaigns/CampaignStatePill";
import PageHeader, { Button } from "../patterns/PageHeader";
import TabNavigation from "../patterns/TabNavigation";
import PersonProfile from "../person/PersonProfile";
import OrgProfile from "../org/OrgProfile";
import DeleteModal from "./DeleteModal";
import SetLiveModal from "./SetLiveModal";
import MemberTable from "./MemberTable";
import CohortForm from "./cohort_form";
import { updateCohortReducer } from "./cohort_form/reducer";
import { CohortTabs, isCohortDirty } from "./lib";
import Breadcrumbs from "../patterns/Breadcrumbs";

export const VIEW_PARAM = "view";

export default function Cohort() {
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  let view = searchParams.get(VIEW_PARAM) ?? "overview";
  const { data, loading, error } = useQuery(GET_COHORT, {
    variables: { id: params.id },
    fetchPolicy: "cache-and-network",
  });
  const [updateCohortMutation] = useMutation(UPDATE_COHORT);
  const [cohortData, dispatch] = useReducer(updateCohortReducer, null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [setLiveModalVisible, setSetLiveModalVisible] = useState(false);
  const [activeMember, setActiveMember] = useState<PersonType | OrgType>();
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [activeTab, setActiveTab] = useState<CohortTabs>( // eslint-disable-line @typescript-eslint/no-unused-vars
    CohortTabs.LastEntered,
  );
  const closeDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);

  const views = useMemo(
    () => [
      {
        label: "Overview",
        action: () => setSearchParams(`${VIEW_PARAM}=overview`),
        isActive: view === "overview",
      },
      {
        label: "Settings",
        action: () => setSearchParams(`${VIEW_PARAM}=settings`),
        isActive: view === "settings",
      },
    ],
    [setSearchParams, view],
  );

  const updateCohort = useCallback(() => {
    return updateCohortMutation({
      variables: {
        id: params.id,
        name: cohortData.name,
        status: cohortData.status,
        audience: JSON.stringify(cohortData.audience),
        observing: JSON.stringify(cohortData.observing),
        aiSummarySettings: JSON.stringify(cohortData.aiSummarySettings),
      },
    });
  }, [cohortData, params.id, updateCohortMutation]);

  const pauseCohort = useCallback(() => {
    return updateCohortMutation({
      variables: {
        id: params.id,
        name: cohortData.name,
        status: MachineStatusChoices.Paused,
        audience: JSON.stringify(cohortData.audience),
        observing: JSON.stringify(cohortData.observing),
        aiSummarySettings: JSON.stringify(cohortData.aiSummarySettings),
      },
    });
  }, [cohortData, params.id, updateCohortMutation]);

  useEffect(() => {
    if (data?.cohortById) {
      dispatch({
        type: "init",
        data: data?.cohortById,
      });
    }
  }, [data?.cohortById]);

  const isDirty = useMemo(
    () => isCohortDirty(cohortData, data?.cohortById),
    [cohortData, data?.cohortById],
  );

  const rootPage = useMemo(() => {
    if (!cohortData?.targetMemberType) {
      return "dashboard";
    } else {
      return cohortData?.targetMemberType === TargetMemberTypeChoices.Person
        ? "audience/people"
        : "audience/organizations";
    }
  }, [cohortData?.targetMemberType]);

  if (loading) {
    return <SkeletonScreen />;
  }

  if (error) {
    return <FullScreenError message={error.message} />;
  }

  const cohort = data.cohortById;

  const allButtons = {
    delete: {
      label: "Delete",
      type: "secondary",
      action: () => {
        setDeleteModalVisible(true);
      },
    } as Button,
    save: {
      label: "Save",
      type: "primary",
      isDisabled: !isDirty,
      action: () => {
        updateCohort()
          .then(() => toast.success("Cohort saved"))
          .catch((e) => toast.error(`Failed to save cohort: ${e.message}`));
      },
    } as Button,
    setLive: {
      label: `Set Cohort Live`,
      type: "primary",
      action: () => setSetLiveModalVisible(true),
    } as Button,
    pause: {
      label: `Pause Cohort`,
      type: "primary",
      action: () => {
        pauseCohort()
          .then(() => toast.success("Cohort paused"))
          .catch((e) => toast.error(`Failed to cohort behavior: ${e.message}`));
      },
    } as Button,
  };

  let buttons: Button[];
  if (cohort.status === MachineStatusChoices.Draft) {
    buttons = [allButtons.delete, allButtons.save, allButtons.setLive];
  } else if (cohort.status === MachineStatusChoices.Live) {
    buttons = [allButtons.save, allButtons.pause];
  } else if (cohort.status === MachineStatusChoices.Paused) {
    buttons = [allButtons.save, allButtons.setLive];
  }

  return (
    <div>
      {deleteModalVisible && (
        <DeleteModal
          cohort={cohortData}
          close={() => setDeleteModalVisible(false)}
        />
      )}
      {setLiveModalVisible && (
        <SetLiveModal
          cohort={cohortData}
          close={() => setSetLiveModalVisible(false)}
        />
      )}
      {drawerIsVisible && activeMember && (
        <Drawer close={closeDrawer}>
          <div className="mt-10">
            {activeMember.__typename === "PersonType" ? (
              <PersonProfile id={activeMember.id} />
            ) : (
              <OrgProfile id={activeMember.id} />
            )}
          </div>
        </Drawer>
      )}

      <Breadcrumbs
        crumbs={[
          {
            label:
              cohortData?.targetMemberType === TargetMemberTypeChoices.Person
                ? "People"
                : "Organizations",
            link: `/${rootPage}`,
          },
          { label: "Cohorts" },
          { label: cohort.name },
        ]}
        icon={<DashboardIcon strokeColor={"white"} strokeWidth={1.5} />}
      />

      <div className="flex flex-col max-w-8xl mx-auto mt-0">
        <PageHeader
          header={cohort.name}
          subhead=""
          headerPill={<CampaignStatusPill status={cohort.status} />}
          buttons={buttons}
          slug={`Targeting: ${
            cohort.targetMemberType === TargetMemberTypeChoices.Person
              ? "Individuals"
              : "Organizations"
          }`}
        />
        <TabNavigation tabs={views} />
        {view === "overview" && (
          <>
            <MemberTable
              cohort={cohort}
              activeTab={activeTab}
              showMember={(member) => {
                setActiveMember(member);
                setDrawerIsVisible(true);
              }}
            />
          </>
        )}
        {view === "settings" && !loading && cohortData && (
          <CohortForm dispatch={dispatch} cohort={cohortData} />
        )}
      </div>
    </div>
  );
}
