import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import RevenueImpact from "./RevenueImpact";
import { InterventionTypes } from "../lib";
import EngagementCircle from "../../patterns/EngagementCircle";
import PageHeader from "../../patterns/PageHeader";

export default function EntryHeaderWrapper({
  isSelected,
  name,
  description,
  circleFill,
  totalRevenue,
  children,
  type,
}: {
  isSelected: boolean;
  name: string;
  description: string;
  circleFill: number;
  totalRevenue: number;
  children: React.ReactNode;
  type?: InterventionTypes;
}) {
  const wrapperClasses =
    "text-body-text transition-all duration-1000 ease-in-out flex flex-col gap-4";
  const selectedWrapperClasses = isSelected
    ? "opacity-100 max-h-[1000px] overflow-visible"
    : "opacity-0 max-h-0 overflow-hidden";

  const revenueLabel =
    type === InterventionTypes.UpsellCohort
      ? "Revenue Opportunity"
      : "Impacted Revenue";

  return (
    <>
      <div className="flex flex-row items-center cursor-pointer gap-4">
        <div className="w-12 h-12">
          {type === InterventionTypes.UpsellCohort ? (
            <ArrowUpRightIcon className="text-body-text-lighter w-8 h-8 mt-2 ml-1" />
          ) : (
            <EngagementCircle
              completionPercentage={circleFill}
              showText={false} // Some Funny Business going on with the email %
            />
          )}
        </div>
        <div className="flex flex-grow">
          <PageHeader
            header={name}
            subhead={description}
            xPadding={false}
            yPadding={false}
          />
        </div>
        <RevenueImpact totalRevenue={totalRevenue} label={revenueLabel} />
      </div>

      <div
        className={`${wrapperClasses} ${selectedWrapperClasses}`}
        data-testid={`intervention-entry-header-${name}`}
      >
        {children}
      </div>
    </>
  );
}
