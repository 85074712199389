import { useEffect, useState } from "react";

export default function ClimbingNumber({ value }: { value: number }) {
  const [displayedValue, setDisplayedValue] = useState(0);

  useEffect(() => {
    let start = 0;
    const duration = 300;
    const stepTime = 2;
    const stepCount = Math.ceil(duration / stepTime);
    const increment = value / stepCount;

    const interval = setInterval(() => {
      start += increment;
      if (start >= value) {
        start = value;
        clearInterval(interval);
      }
      setDisplayedValue(Math.round(start));
    }, stepTime);

    return () => clearInterval(interval);
  }, [value]);

  return <span>{displayedValue.toLocaleString()}</span>;
}
