import { Popover } from "@headlessui/react";
import React, { useState, useMemo, useCallback } from "react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/20/solid";

interface Props {
  placeholder?: string;
  label: string;
  options: Array<{ label: string; value: string | number; disabled?: boolean }>;
  value: string;
  onChange: (s: string) => void;
}

export default function SearchSelect({
  placeholder,
  label,
  options,
  value,
  onChange,
}: Props) {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(
    options.findIndex((o) => o.value.toString() === value),
  );

  const filteredOptions = useMemo(() => {
    if (!searchQuery) return options;
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [options, searchQuery]);

  const selectedLabel = options.find(
    (o) => o.value.toString() === value,
  )?.label;

  const keyDown = useCallback(
    (e: React.KeyboardEvent, close: () => void) => {
      switch (e.key) {
        case "ArrowDown":
          if (selectedIndex < filteredOptions.length - 1) {
            setSelectedIndex(selectedIndex + 1);
          } else {
            setSelectedIndex(0);
          }
          break;
        case "ArrowUp":
          if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
          } else {
            setSelectedIndex(filteredOptions.length - 1);
          }
          break;
        case "Enter":
          console.log("keydown", e);
          e.preventDefault();
          e.stopPropagation();
          const option = filteredOptions[selectedIndex];
          onChange(option.value.toString());
          close();
          break;
        case "Escape":
          e.preventDefault();
          e.stopPropagation();
          close();
          break;
      }
    },
    [filteredOptions, selectedIndex, onChange, setSelectedIndex],
  );

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className="m-0 ml-2 flex w-full items-center justify-around gap-2 rounded-full border border-rule-color bg-white py-2.5 px-4 text-xs hover:bg-grey-100 hover:border-slate-40 animate"
            data-testid={`search-select-button-${label}`}
          >
            <div className="whitespace-nowrap font-normal text-body-text-lighter">
              {label}:
            </div>
            <div className="w-full flex items-center justify-between">
              <span className="pl-1 pr-2 font-semibold text-body-text">
                {selectedLabel}
              </span>
              <ChevronDownIcon className="h-4 w-4 text-body-text" />
            </div>
          </Popover.Button>
          <Popover.Panel
            className="absolute z-10 mt-2 w-full rounded-lg border border-rule-color bg-white py-1 shadow-lg"
            onKeyDown={(e) => {
              keyDown(e, close);
            }}
          >
            {options.length >= 10 && (
              <div className="px-3 py-2">
                <input
                  type="text"
                  className="w-full rounded-lg border border-rule-color px-2 pl-3 text-xs font-normal leading-6 tracking-wide text-body-text"
                  placeholder={placeholder}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  autoFocus
                />
              </div>
            )}

            <div className="max-h-60 overflow-auto">
              {filteredOptions.map((option, index) => (
                <div
                  key={option.value}
                  className={`flex w-full px-3 py-2 text-sm leading-6 text-body-text-lighter align-middle items-center justify-between ${
                    option.disabled
                      ? "cursor-not-allowed text-gray-400"
                      : "cursor-pointer hover:bg-grey-100"
                  } ${selectedIndex === index ? "bg-grey-50" : ""}`}
                  onClick={() => {
                    if (!option.disabled) {
                      onChange(option.value.toString());
                      close();
                    }
                  }}
                  data-testid={`search-select-option-${option.value}`}
                >
                  <span>{option.label}</span>
                  {option.value.toString() === value && (
                    <CheckIcon className="h-4 w-4 text-body-text" />
                  )}
                </div>
              ))}
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
