import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import {
  MachineStatusChoices,
  TargetMemberTypeChoices,
} from "../__generated__/graphql";
import { GET_LIFECYCLE_STAGE_BY_ID } from "../graphql/queries";
import { UPDATE_BEHAVIOR } from "../graphql/mutations";
import { updateBehaviorReducer } from "../behavior/behavior_form/reducer";
import { canBehaviorBeSetLive, isBehaviorDirty } from "../behavior/lib";
import SkeletonScreen from "../patterns/Skeleton";
import FullScreenError from "../patterns/Errors";
import PageHeader, { Button } from "../patterns/PageHeader";
import DashboardIcon from "../patterns/symbols/DashboardIcon";
import TabNavigation from "../patterns/TabNavigation";
import Breadcrumbs from "../patterns/Breadcrumbs";
import SetLiveModal from "./SetLiveModal";
import CampaignStatusPill from "../campaigns/CampaignStatePill";
import { emptyBehavior } from "../lib/behaviors";
import LifecycleStageOverview from "./LifecycleStageOverview";
import LifecycleStageForm from "./LifecycleStageForm";

export const VIEW_PARAM = "view";

export default function LifecycleStage() {
  const [setLiveModalVisible, setSetLiveModalVisible] = useState(false);
  const [updateBehaviorMutation] = useMutation(UPDATE_BEHAVIOR);
  const [behaviorData, dispatch] = useReducer(updateBehaviorReducer, null);
  const [searchParams, setSearchParams] = useSearchParams();

  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useQuery(GET_LIFECYCLE_STAGE_BY_ID, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });
  let lifecycleStage = data?.lifecycleStageById;

  const updateBehavior = useCallback(
    async (status: MachineStatusChoices) => {
      if (!behaviorData?.id)
        return {
          ok: false,
          message: "Lifecycle Stage behavior does not exist",
        };
      try {
        const response = await updateBehaviorMutation({
          variables: {
            id: behaviorData.id,
            name: behaviorData.name,
            status: status,
            audience: JSON.stringify(behaviorData.audience),
            observing: JSON.stringify(behaviorData.observing),
            actionsOnEnter: JSON.stringify(behaviorData.actionsOnEnter),
            actionsOnCompletion: JSON.stringify(
              behaviorData.actionsOnCompletion,
            ),
            actionsWhileObserving: JSON.stringify(
              behaviorData.actionsWhileObserving,
            ),
          },
        });
        return { ok: response.data.updateBehavior.ok };
      } catch (e) {
        return { ok: false, message: e.message };
      }
    },
    [behaviorData, updateBehaviorMutation],
  );

  const currentBehavior = data?.lifecycleStageById.behavior || emptyBehavior;

  useEffect(() => {
    if (currentBehavior) {
      dispatch({ type: "init", data: currentBehavior });
    }
  }, [currentBehavior]);

  const view = searchParams.get(VIEW_PARAM) ?? "overview";
  const views = useMemo(
    () => [
      {
        label: "Overview",
        action: () => setSearchParams(`${VIEW_PARAM}=overview`),
        isActive: view === "overview",
      },
      {
        label: "Settings",
        action: () => setSearchParams(`${VIEW_PARAM}=settings`),
        isActive: view === "settings",
      },
    ],
    [setSearchParams, view],
  );

  const isDirty = useMemo(
    () => isBehaviorDirty(behaviorData, currentBehavior),
    [behaviorData, currentBehavior],
  );

  const canSetLive = useMemo(
    () => canBehaviorBeSetLive(behaviorData),
    [behaviorData],
  );

  if (loading) {
    return <SkeletonScreen />;
  }

  if (error) {
    return <FullScreenError message={error.message} />;
  }

  lifecycleStage = data.lifecycleStageById;
  const behavior = lifecycleStage.behavior || emptyBehavior;

  const allButtons = {
    save: {
      label: "Save",
      type: "primary",
      isDisabled: !isDirty,
      action: async () => {
        const response = await updateBehavior(
          behaviorData.status as MachineStatusChoices,
        );
        if (response.ok) {
          toast.success("Lifecycle Stage saved");
        } else {
          toast.error(`Failed to save Lifecycle Stage: ${response.message}`);
        }
      },
    } as Button,
    pause: {
      label: `Pause Lifecycle Stage`,
      type: "primary",
      action: async () => {
        const response = await updateBehavior(MachineStatusChoices.Paused);
        if (response.ok) {
          toast.success("Lifecycle Stage paused");
        } else {
          toast.error(`Failed to pause Lifecycle Stage: ${response.message}`);
        }
      },
    } as Button,
    setLive: {
      label: `Set Lifecycle Stage Live`,
      type: "primary",
      isDisabled: !canSetLive,
      action: () => setSetLiveModalVisible(true),
    } as Button,
  };

  let buttons: Button[];
  if (
    behavior.status === MachineStatusChoices.Draft ||
    behavior.status === MachineStatusChoices.Paused
  ) {
    buttons = [allButtons.save, allButtons.setLive];
  } else if (behavior.status === MachineStatusChoices.Live) {
    buttons = [allButtons.save, allButtons.pause];
  }

  return (
    <div>
      {setLiveModalVisible && (
        <SetLiveModal
          setLive={async () => {
            const response = await updateBehavior(MachineStatusChoices.Live);
            if (response.ok) {
              toast.success("Lifecycle Stage set Live");
            } else {
              toast.error(
                `Failed to set Lifecycle Stage live: ${response.message}`,
              );
            }
          }}
          close={() => setSetLiveModalVisible(false)}
        />
      )}

      <Breadcrumbs
        crumbs={[
          { label: "Dashboard", link: "/dashboard/lifecycle_stages" },
          { label: lifecycleStage.name },
        ]}
        icon={<DashboardIcon strokeColor={"white"} strokeWidth={1.5} />}
      />

      <div className="flex flex-col max-w-8xl mx-auto mt-0">
        <PageHeader
          header={lifecycleStage.name}
          subhead="Lifecycle Stage"
          headerPill={
            <CampaignStatusPill
              status={behavior.status as MachineStatusChoices}
            />
          }
          buttons={buttons}
          slug={`Targeting: ${
            behavior.targetMemberType === TargetMemberTypeChoices.Person
              ? "Individuals"
              : "Organizations"
          }`}
        />
        <TabNavigation tabs={views} />
        {view === "overview" && (
          <LifecycleStageOverview lifecycleStage={lifecycleStage} />
        )}
        {view === "settings" && !loading && (
          <LifecycleStageForm
            lifecycleStage={lifecycleStage}
            behavior={behaviorData}
            dispatch={dispatch}
          />
        )}
      </div>
    </div>
  );
}
