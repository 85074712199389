import { AttrTypeEnum, AttributeTarget } from "../__generated__/graphql";
import { format } from "date-fns";
import NumberInput from "./inputs/NumberInput";
import { TextInput } from "./inputs/TextInput";
import BooleanInput from "./inputs/BooleanInput";
import { DateInput } from "./inputs/DateInput";
import { DateTimeInput } from "./inputs/DateTimeInput";
import {
  BinaryFilterCondition,
  Filter,
  FilterGroup,
  TimeDelta,
  UnaryFilterCondition,
} from "../types/BackendTypes";
import { AttributeGroup } from "../SessionContext";
import {
  isBinaryFilterCondition,
  isFilterGroup,
  isTimeDelta,
} from "../lib/filters";
import { pluralize } from "../lib/string";
import { operatorSymbolMapRelativeDates } from "./RelativeDateOption";
import { operatorSymbolMap } from "./FilterConditionEditor";
import {
  PersonAttributeType,
  OrgAttributeType,
} from "../__generated__/graphql";
import MixPanelLogo from "../patterns/symbols/MixPanelLogo";
import HubspotLogo from "../patterns/symbols/HubspotLogo";
import SegmentLogo from "../patterns/symbols/SegmentLogo";
import SnowflakeLogo from "../patterns/symbols/SnowflakeLogo";
import AmplitudeLogo from "../patterns/symbols/AmplitudeLogo";
import SalesforceLogo from "../patterns/symbols/SalesforceLogo";
import BooleanIcon from "../patterns/symbols/BooleanIcon";
import DateTimeIcon from "../patterns/symbols/DateTimeIcon";
import StringIcon from "../patterns/symbols/StringIcon";
import { CalendarIcon, TagIcon } from "@heroicons/react/24/solid";
import { ClockIcon, VariableIcon } from "@heroicons/react/24/outline";

export type AttributeType = PersonAttributeType | OrgAttributeType;

export type IncentiveAttributeType = {
  slug: string;
  name: string;
  displayName: string;
};

export const incentiveAttributes: [IncentiveAttributeType] = [
  {
    slug: "incentive.shopify_gift.gift_redemption_url",
    name: "gift_redemption_url",
    displayName: "Gift Redeem URL",
  },
];

export const FILTER_DOC_VERSION = "1";

export enum Operators {
  EQ = "EQ",
  NE = "NE",
  GT = "GT",
  LT = "LT",
  NOT_NULL = "NOT_NULL",
  NULL = "NULL",
}

export const relativeDateOperators = [Operators.GT, Operators.LT, Operators.EQ];

export type FilterCondition = BinaryFilterCondition | UnaryFilterCondition;

export interface ValueComponentProps {
  value: any;
  disabled?: boolean;
  conditionTarget?: FilterConditionTarget;
  onChange: (s: any) => void;
}
export interface Config {
  operators: Operators[];
  dataType: AttrTypeEnum;
  valueComponent: ({
    value,
    disabled,
    conditionTarget,
    onChange,
  }: ValueComponentProps) => React.ReactElement;
  defaultValue: any;
}

export const attrTypeMap: { [key in AttrTypeEnum]: Config } = {
  [AttrTypeEnum.Int]: {
    operators: [
      Operators.EQ,
      Operators.NE,
      Operators.GT,
      Operators.LT,
      Operators.NOT_NULL,
      Operators.NULL,
    ],
    valueComponent: NumberInput,
    defaultValue: 1,
    dataType: AttrTypeEnum.Int,
  },
  [AttrTypeEnum.String]: {
    operators: [Operators.EQ, Operators.NE, Operators.NOT_NULL, Operators.NULL],
    valueComponent: TextInput,
    defaultValue: "",
    dataType: AttrTypeEnum.String,
  },
  [AttrTypeEnum.Boolean]: {
    operators: [Operators.EQ, Operators.NE, Operators.NOT_NULL, Operators.NULL],
    valueComponent: BooleanInput,
    defaultValue: true,
    dataType: AttrTypeEnum.Boolean,
  },
  [AttrTypeEnum.Date]: {
    operators: [
      Operators.EQ,
      Operators.GT,
      Operators.LT,
      Operators.NOT_NULL,
      Operators.NULL,
    ],
    valueComponent: DateInput,
    defaultValue: format(new Date(), "yyyy-MM-dd"),
    dataType: AttrTypeEnum.Date,
  },
  [AttrTypeEnum.Time]: {
    operators: [
      Operators.EQ,
      Operators.GT,
      Operators.LT,
      Operators.NOT_NULL,
      Operators.NULL,
    ],
    valueComponent: TextInput,
    defaultValue: format(new Date(), "kk:00"),
    dataType: AttrTypeEnum.Time,
  },
  [AttrTypeEnum.Datetime]: {
    operators: [
      Operators.EQ,
      Operators.GT,
      Operators.LT,
      Operators.NOT_NULL,
      Operators.NULL,
    ],
    valueComponent: DateTimeInput,
    defaultValue: format(new Date(), "yyyy-MM-dd'T'kk:00"),
    dataType: AttrTypeEnum.Datetime,
  },
  [AttrTypeEnum.Unknown]: {
    operators: [Operators.EQ, Operators.NE, Operators.NOT_NULL, Operators.NULL],
    valueComponent: TextInput,
    defaultValue: "",
    dataType: AttrTypeEnum.String,
  },
};

const eventConfig: Config = {
  operators: [Operators.NULL, Operators.NOT_NULL],
  valueComponent: TextInput,
  defaultValue: "",
  dataType: AttrTypeEnum.String,
};

export function findAttribute(
  name,
  source,
  target: AttributeTarget = AttributeTarget.Person,
  attributes: AttributeGroup,
) {
  return attributes[target].find((a) => {
    return a.name === name && a.source === source;
  });
}

type EventTarget = string;

export type FilterConditionTarget = AttributeType | EventTarget;

export function findTargetForCondition(
  c: FilterCondition,
  attributes: AttributeGroup,
): FilterConditionTarget {
  if (c.target === "event") {
    return c.path;
  }
  return attributes[c.target.toLowerCase() || "person"].find((a) => {
    return a.name === c.path && a.source === c.source;
  });
}

export function configForTarget(target: FilterConditionTarget) {
  if (typeof target === "string") {
    return eventConfig;
  }
  return attrTypeMap[target.aType];
}

export function formatTimeDelta(v: TimeDelta) {
  return `${pluralize(v.value, v.unit)} ${
    v.direction === "past" ? "ago" : "from now"
  } `;
}

export function formatValue(type: AttrTypeEnum, condition: FilterCondition) {
  if (!isBinaryFilterCondition(condition)) {
    return "";
  }
  const value = condition.value;
  if (value === null) {
    return "";
  }
  if (isTimeDelta(value)) {
    return formatTimeDelta(value);
  }
  switch (type) {
    case AttrTypeEnum.String:
      return `"${value}"`;
    case AttrTypeEnum.Datetime:
      try {
        return format(new Date(value as string), "MMM dd, yyyy kk:mm");
      } catch {
        return "Invalid Datetime";
      }
    case AttrTypeEnum.Boolean:
      return value ? "true" : "false";
    case AttrTypeEnum.Date:
      try {
        return format(new Date(value as string), "MMM dd, yyyy");
      } catch {
        return "Invalid Date";
      }
    case AttrTypeEnum.Int:
      return value.toLocaleString();
    case AttrTypeEnum.Time:
      return value;
    case AttrTypeEnum.Unknown:
      return value;
    default:
      const exhaustiveCheck: never = type;
      throw new Error(`Unknown action type ${exhaustiveCheck}`);
  }
}

export function formatOperator(c: FilterCondition) {
  return isBinaryFilterCondition(c) && isTimeDelta(c.value)
    ? operatorSymbolMapRelativeDates[c.operator]
    : operatorSymbolMap[c.operator];
}

export function attrIcon(source: string, type: AttrTypeEnum) {
  if (source !== "api") {
    return sourceIcon(source);
  }
  switch (type) {
    case AttrTypeEnum.Date:
      return CalendarIcon;
    case AttrTypeEnum.Time:
      return ClockIcon;
    case AttrTypeEnum.Datetime:
      return DateTimeIcon;
    case AttrTypeEnum.Boolean:
      return BooleanIcon;
    case AttrTypeEnum.String:
      return StringIcon;
    case AttrTypeEnum.Int:
      return VariableIcon;
    case AttrTypeEnum.Unknown:
      return TagIcon;
    default:
      const exhaustiveCheck: never = type;
      throw new Error(`Unknown data type ${exhaustiveCheck}`);
  }
}

export function sourceIcon(source) {
  if (source === "hubspot") {
    return HubspotLogo;
  }
  if (source === "mixpanel") {
    return MixPanelLogo;
  }
  if (source === "segment") {
    return SegmentLogo;
  }
  if (source === "snowflake") {
    return SnowflakeLogo;
  }
  if (source === "amplitude") {
    return AmplitudeLogo;
  }
  if (source === "salesforce") {
    return SalesforceLogo;
  }
  return TagIcon;
}

export function buildNewCondition(
  item: AttributeType | string,
): FilterCondition {
  if (typeof item === "string") {
    return {
      path: item,
      source: "api",
      target: "event",
      operator: "NOT_NULL" as any,
    };
  }
  const config = configForTarget(item);
  return {
    path: item.name,
    source: item.source,
    target: item.target,
    operator: config.operators[0] as any,
    value: config.defaultValue,
  };
}

export function pushConditionDownToNewGroup(
  filterGroup: FilterGroup,
  filterCondition,
): FilterGroup {
  const index = filterGroup.conditions.indexOf(filterCondition);
  const newConditions = [...filterGroup.conditions];

  const newGroup: FilterGroup = {
    operator: "AND",
    conditions: [filterCondition],
  };

  newConditions[index] = newGroup;
  return {
    operator: filterGroup.operator,
    conditions: newConditions,
  };
}

export function filterDocIsComplete(f: Filter) {
  return filterGroupIsComplete(f.filters);
}

function filterGroupIsComplete(f: FilterGroup) {
  return f.conditions.every((c) => {
    if (isBinaryFilterCondition(c)) {
      return c.value !== null && c.value !== "";
    } else if (isFilterGroup(c)) {
      return filterGroupIsComplete(c);
    }
    return true;
  });
}

export function filterDocIsEmpty(f: Filter) {
  return filterGroupIsEmpty(f.filters);
}

function filterGroupIsEmpty(f: FilterGroup) {
  return f.conditions.length === 0;
}
