export default function LoginBG() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1917 1623"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M282 882L282 816.401L396 750.802L396 816.401L282 882Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M396 750.802L339 718L225 783.599L282 816.401L396 750.802Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M282 816.401L225 783.599L225 849.198L282 882"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M282 882L282 816.401M282 882L396 816.401L396 750.802M282 882L225 849.198L225 783.599M282 816.401L396 750.802M282 816.401L225 783.599M396 750.802L339 718L225 783.599M391.611 813.873L388.647 812.169M381.978 808.328L345.299 787.223M341.964 785.303L339 783.599M278.437 818.992L275.445 820.651M269.152 824.133L234.542 843.295M231.395 845.034L228.403 846.692M339 779.276L339 775.867M339 768.669L339 729.106M339 725.505L339 722.096"
          stroke="#F6F6F6"
          stroke-miterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M1447.67 217L1447.67 151.401L1561 85.8023L1561 151.401L1447.67 217Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1561 85.8023L1504.33 53L1391 118.599L1447.67 151.401L1561 85.8023Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1447.67 151.401L1391 118.599L1391 184.198L1447.67 217"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1447.67 217L1447.67 151.401M1447.67 217L1561 151.401L1561 85.8023M1447.67 217L1391 184.198L1391 118.599M1447.67 151.401L1561 85.8023M1447.67 151.401L1391 118.599M1561 85.8023L1504.33 53L1391 118.599M1556.64 148.873L1553.69 147.169M1547.06 143.328L1510.6 122.223M1507.28 120.303L1504.33 118.599M1444.12 153.992L1441.15 155.651M1434.89 159.133L1400.49 178.295M1397.36 180.034L1394.38 181.692M1504.33 114.276L1504.33 110.867M1504.33 103.669L1504.33 64.1064M1504.33 60.5046L1504.33 57.096"
          stroke="#F6F6F6"
          stroke-miterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M1590.18 721.813L1646.98 754.609L1647.02 885.72L1590.22 852.925L1590.18 721.813Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1647.02 885.72L1703.81 852.962L1703.77 721.85L1646.98 754.609L1647.02 885.72Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1646.98 754.609L1703.77 721.85L1646.96 689.054L1590.18 721.813"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1590.18 721.813L1646.98 754.609M1590.18 721.813L1590.22 852.925L1647.02 885.72M1590.18 721.813L1646.96 689.054L1703.77 721.85M1646.98 754.609L1647.02 885.72M1646.98 754.609L1703.77 721.85M1647.02 885.72L1703.81 852.962L1703.77 721.85M1594.59 850.403L1597.54 848.699M1604.19 844.868L1640.73 823.786M1644.05 821.87L1647 820.166M1646.51 750.241L1646.56 746.831M1646.68 739.663L1647.32 700.234M1647.38 696.652L1647.44 693.241M1650.75 822.327L1653.7 824.031M1659.93 827.63L1694.19 847.409M1697.31 849.21L1700.26 850.914"
          stroke="#F6F6F6"
          stroke-miterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M1590.33 919V853.401L1477 787.802V853.401L1590.33 919Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1477 787.802L1533.67 755L1647 820.599L1590.33 853.401L1477 787.802Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1590.33 853.401L1647 820.599V886.198L1590.33 919"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1590.33 919V853.401M1590.33 919L1477 853.401V787.802M1590.33 919L1647 886.198V820.599M1590.33 853.401L1477 787.802M1590.33 853.401L1647 820.599M1477 787.802L1533.67 755L1647 820.599M1481.36 850.873L1484.31 849.169M1490.94 845.328L1527.4 824.223M1530.72 822.303L1533.67 820.599M1593.87 855.992L1596.85 857.651M1603.11 861.133L1637.51 880.295M1640.64 882.034L1643.62 883.692M1533.67 816.276V812.867M1533.67 805.669V766.106M1533.67 762.505V759.096"
          stroke="#F6F6F6"
          stroke-miterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M740 401V335.401L626 269.802V335.401L740 401Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M626 269.802L683 237L797 302.599L740 335.401L626 269.802Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M740 335.401L797 302.599V368.198L740 401"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M740 401V335.401M740 401L626 335.401V269.802M740 401L797 368.198V302.599M740 335.401L626 269.802M740 335.401L797 302.599M626 269.802L683 237L797 302.599M630.389 332.873L633.353 331.169M640.022 327.328L676.701 306.223M680.036 304.303L683 302.599M743.562 337.992L746.555 339.651M752.848 343.133L787.458 362.295M790.605 364.034L793.597 365.692M683 298.276V294.867M683 287.669V248.106M683 244.505V241.096"
          stroke="#F6F6F6"
          stroke-miterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M198.023 928.261L253.765 896.078L253.763 831.713L198.02 863.897L198.023 928.261Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M198.02 863.897L142.28 831.712L198.022 799.529L253.763 831.713"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M142.217 831.748L198.083 863.86L197.96 928.298L142.215 896.116L142.217 831.748Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M253.763 831.713L253.765 896.078L198.023 928.261L198.02 863.897M253.763 831.713L198.02 863.897M253.763 831.713L198.022 799.529L142.28 831.712L198.02 863.897M142.217 831.748L198.083 863.86L197.96 928.298L142.215 896.116L142.217 831.748Z"
          stroke="#F6F6F6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="8 8"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M1363.55 263.506L1419.29 231.323L1419.29 166.958L1363.55 199.141L1363.55 263.506Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1363.55 199.141L1307.81 166.957L1363.55 134.774L1419.29 166.958"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1307.75 166.993L1363.61 199.105L1363.49 263.542L1307.74 231.361L1307.75 166.993Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1419.29 166.958L1419.29 231.323L1363.55 263.506L1363.55 199.141M1419.29 166.958L1363.55 199.141M1419.29 166.958L1363.55 134.774L1307.81 166.957L1363.55 199.141M1307.75 166.993L1363.61 199.105L1363.49 263.542L1307.74 231.361L1307.75 166.993Z"
          stroke="#F6F6F6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="8 8"
        />
      </g>
      <path
        d="M1340.68 586.361L1380.89 609.571M1340.68 586.361L1340.7 679.172L1380.9 702.381M1340.68 586.361L1380.88 563.167L1421.08 586.377M1380.89 609.571L1380.9 702.381M1380.89 609.571L1421.08 586.377M1380.9 702.381L1421.1 679.187L1421.08 586.377M1343.8 677.386L1345.89 676.18M1350.59 673.467L1376.45 658.54M1378.81 657.184L1380.9 655.977M1380.55 606.479L1380.59 604.066M1380.68 598.991L1381.13 571.081M1381.18 568.545L1381.21 566.131M1383.54 657.507L1385.63 658.713M1390.04 661.26L1414.29 675.257M1416.5 676.532L1418.59 677.738"
        stroke="#F6F6F6"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M294.541 1253.42L334.742 1276.63M294.541 1253.42L294.558 1346.23L334.758 1369.44M294.541 1253.42L334.735 1230.23L374.936 1253.44M334.742 1276.63L334.758 1369.44M334.742 1276.63L374.936 1253.44M334.758 1369.44L374.952 1346.25L374.936 1253.44M297.654 1344.45L299.743 1343.24M304.448 1340.53L330.31 1325.6M332.662 1324.24L334.752 1323.04M334.41 1273.54L334.448 1271.13M334.532 1266.05L334.989 1238.14M335.033 1235.6L335.071 1233.19M337.401 1324.57L339.49 1325.77M343.901 1328.32L368.146 1342.32M370.353 1343.59L372.442 1344.8"
        stroke="#F6F6F6"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M767.655 543.998L807.855 567.208M767.655 543.998L848.039 497.607L888.24 520.817M767.655 543.998L767.665 590.404L807.865 613.614M807.855 567.208L888.24 520.817M807.855 567.208L807.865 613.614M888.24 520.817L888.25 567.223L807.865 613.614M848.041 501.181L848.041 503.594M848.044 509.024L848.048 538.885M848.05 541.601L848.049 544.013M805.012 568.466L802.941 569.706M798.588 572.316L774.645 586.667M772.471 587.973L770.4 589.213M850.699 545.543L852.788 546.749M857.199 549.296L881.444 563.294M883.651 564.568L885.74 565.774"
        stroke="#F6F6F6"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1009.84 1111.63L1050.04 1134.84M1009.84 1111.63L1090.23 1065.23L1130.43 1088.44M1009.84 1111.63L1009.85 1158.03L1050.05 1181.24M1050.04 1134.84L1130.43 1088.44M1050.04 1134.84L1050.05 1181.24M1130.43 1088.44L1130.44 1134.85L1050.05 1181.24M1090.23 1068.81L1090.23 1071.22M1090.23 1076.65L1090.24 1106.51M1090.24 1109.23L1090.24 1111.64M1047.2 1136.09L1045.13 1137.33M1040.78 1139.94L1016.83 1154.29M1014.66 1155.6L1012.59 1156.84M1092.89 1113.17L1094.98 1114.38M1099.39 1116.92L1123.63 1130.92M1125.84 1132.2L1127.93 1133.4"
        stroke="#F6F6F6"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M455.565 271.852L415.599 294.927L415.583 387.196L455.549 364.122L455.565 271.852Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M415.583 387.196L375.623 364.137L375.639 271.867L415.599 294.927L415.583 387.196Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M415.599 294.927L375.639 271.867L415.606 248.793L455.565 271.852"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M455.565 271.852L415.599 294.927M455.565 271.852L455.549 364.122L415.583 387.196M455.565 271.852L415.606 248.793L375.639 271.867M415.599 294.927L415.583 387.196M415.599 294.927L375.639 271.867M415.583 387.196L375.623 364.137L375.639 271.867M452.471 362.347L450.394 361.147M445.717 358.45L420.005 343.61M417.666 342.262L415.589 341.062M415.929 291.853L415.891 289.453M415.807 284.409L415.353 256.661M415.31 254.139L415.272 251.74M412.955 342.583L410.878 343.782M406.493 346.314L382.389 360.23M380.195 361.497L378.118 362.696"
        stroke="#F6F6F6"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M242.417 454.657L202.216 477.867M242.417 454.657L162.032 408.266L121.832 431.476M242.417 454.657L242.406 501.063L202.206 524.273M202.216 477.867L121.832 431.476M202.216 477.867L202.206 524.273M121.832 431.476L121.821 477.882L202.206 524.273M162.03 411.84L162.03 414.253M162.028 419.683L162.023 449.545M162.022 452.26L162.022 454.672M205.059 479.125L207.131 480.365M211.483 482.975L235.426 497.326M237.6 498.632L239.672 499.872M159.373 456.202L157.284 457.408M152.873 459.955L128.628 473.953M126.42 475.227L124.332 476.433"
        stroke="#F6F6F6"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1636.29 415.801L1636.29 462.131L1596.17 485.296L1596.17 438.967M1636.29 415.801L1596.17 438.967M1636.29 415.801L1596.17 392.635L1556.04 415.8L1596.17 438.967M1556 415.826L1596.21 438.94L1596.12 485.322L1556 462.158L1556 415.826Z"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M666.276 1477.9L666.277 1524.23L626.154 1547.39L626.153 1501.06M666.276 1477.9L626.153 1501.06M666.276 1477.9L626.154 1454.73L586.031 1477.9L626.153 1501.06M585.986 1477.92L626.198 1501.04L626.109 1547.42L585.984 1524.25L585.986 1477.92Z"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1457.17 1157.5L1457.17 1203.83L1417.05 1227L1417.05 1180.67M1457.17 1157.5L1417.05 1180.67M1457.17 1157.5L1417.05 1134.34L1376.93 1157.5L1417.05 1180.67M1376.88 1157.53L1417.09 1180.64L1417 1227.02L1376.88 1203.86L1376.88 1157.53Z"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M354.954 420.965L395.077 397.8L395.075 351.47L354.952 374.635L354.954 420.965Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M354.952 374.635L314.831 351.469L354.954 328.304L395.075 351.47"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M314.786 351.495L354.998 374.609L354.909 420.991L314.784 397.827L314.786 351.495Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M395.075 351.47L395.077 397.8L354.954 420.965L354.952 374.635M395.075 351.47L354.952 374.635M395.075 351.47L354.954 328.304L314.831 351.469L354.952 374.635M314.786 351.495L354.998 374.609L354.909 420.991L314.784 397.827L314.786 351.495Z"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.5">
        <path
          d="M487.423 673.474L542.686 641.568L542.683 577.757L487.42 609.664L487.423 673.474Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M487.42 609.664L432.16 577.756L487.423 545.85L542.683 577.757"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M432.097 577.792L487.483 609.628L487.36 673.51L432.095 641.606L432.097 577.792Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M542.683 577.757L542.686 641.568L487.423 673.474L487.42 609.664M542.683 577.757L487.42 609.664M542.683 577.757L487.423 545.85L432.16 577.756L487.42 609.664M432.097 577.792L487.483 609.628L487.36 673.51L432.095 641.606L432.097 577.792Z"
          stroke="#F6F6F6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M1857 718L1817 695.015V649.041L1857 672.026M1857 718V672.026M1857 718L1897 695.015V649.041L1857 672.026M1897 648.989L1857 672.078L1817 648.989L1857 626L1897 648.989Z"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="8 8"
      />
      <path
        d="M1790.5 1222L1740 1193.02V1135.05L1790.5 1164.03M1790.5 1222V1164.03M1790.5 1222L1841 1193.02V1135.05L1790.5 1164.03M1841 1134.99L1790.5 1164.1L1740 1134.99L1790.5 1106L1841 1134.99Z"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="8 8"
      />
      <path
        d="M61 718L21 695.015L21 649.041L61 672.026M61 718V672.026M61 718L101 695.015L101 649.041L61 672.026M101 648.989L61 672.078L21 648.989L61 626L101 648.989Z"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="8 8"
      />
      <path
        d="M158 1189L118 1165.77L118 1119.29L158 1142.53M158 1189V1142.53M158 1189L198 1165.77V1119.29L158 1142.53M198 1119.24L158 1142.58L118 1119.24L158 1096L198 1119.24Z"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="8 8"
      />
      <path
        d="M1152 423L1112 400.015V354.041L1152 377.026M1152 423V377.026M1152 423L1192 400.015V354.041L1152 377.026M1192 353.989L1152 377.078L1112 353.989L1152 331L1192 353.989Z"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="8 8"
      />
      <path
        d="M770 136L730 112.765V66.2913L770 89.5262M770 136V89.5262M770 136L810 112.765V66.2913L770 89.5262M810 66.2389L770 89.5785L730 66.2389L770 43L810 66.2389Z"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="8 8"
      />
      <path
        d="M958 1436L918 1412.77V1366.29L958 1389.53M958 1436V1389.53M958 1436L998 1412.77V1366.29L958 1389.53M998 1366.24L958 1389.58L918 1366.24L958 1343L998 1366.24Z"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="8 8"
      />
      <path
        d="M756 823L716 799.765V753.291L756 776.526M756 823V776.526M756 823L796 799.765V753.291L756 776.526M796 753.239L756 776.578L716 753.239L756 730L796 753.239Z"
        stroke="#F6F6F6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="8 8"
      />
      <g opacity="0.5">
        <path
          d="M1075.33 947V887.001L972 827.002V887.001L1075.33 947Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M972 827.002L1023.67 797L1127 856.999L1075.33 887.001L972 827.002Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1075.33 887.001L1127 856.999V916.998L1075.33 947"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1075.33 947V887.001M1075.33 947L972 887.001V827.002M1075.33 947L1127 916.998V856.999M1075.33 887.001L972 827.002M1075.33 887.001L1127 856.999M972 827.002L1023.67 797L1127 856.999M975.978 884.689L978.665 883.13M984.71 879.617L1017.96 860.314M1020.98 858.558L1023.67 856.999M1078.56 889.37L1081.27 890.888M1086.98 894.073L1118.35 911.599M1121.2 913.189L1123.92 914.706M1023.67 853.045V849.927M1023.67 843.344V807.158M1023.67 803.864V800.746"
          stroke="#F6F6F6"
          stroke-miterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M1096 899.053V959.019L1147.5 989V929.034L1096 899.053Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1147.5 929.034L1199 899.053V959.019L1147.5 989"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1199 898.986L1147.5 929.101L1096 898.986L1147.5 869L1199 898.986Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1147.5 989L1096 959.019V899.053L1147.5 929.034M1147.5 989V929.034M1147.5 989L1199 959.019V899.053L1147.5 929.034M1199 898.986L1147.5 929.101L1096 898.986L1147.5 869L1199 898.986Z"
          stroke="#F6F6F6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M1009 73.0532V133.019L1060.5 163V103.034L1009 73.0532Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1060.5 103.034L1112 73.0532V133.019L1060.5 163"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1112 72.9857L1060.5 103.101L1009 72.9857L1060.5 43L1112 72.9857Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1060.5 163L1009 133.019V73.0532L1060.5 103.034M1060.5 163V103.034M1060.5 163L1112 133.019V73.0532L1060.5 103.034M1112 72.9857L1060.5 103.101L1009 72.9857L1060.5 43L1112 72.9857Z"
          stroke="#F6F6F6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M1168 942.053V1002.02L1219.5 1032V972.034L1168 942.053Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1219.5 972.034L1271 942.053V1002.02L1219.5 1032"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1271 941.986L1219.5 972.101L1168 941.986L1219.5 912L1271 941.986Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1219.5 1032L1168 1002.02V942.053L1219.5 972.034M1219.5 1032V972.034M1219.5 1032L1271 1002.02V942.053L1219.5 972.034M1271 941.986L1219.5 972.101L1168 941.986L1219.5 912L1271 941.986Z"
          stroke="#F6F6F6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M158 109.803V169.269L210 199V139.533L158 109.803Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M210 139.533L262 109.803V169.269L210 199"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M262 109.736L210 139.6L158 109.736L210 80L262 109.736Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M210 199L158 169.269V109.803L210 139.533M210 199V139.533M210 199L262 169.269V109.803L210 139.533M262 109.736L210 139.6L158 109.736L210 80L262 109.736Z"
          stroke="#F6F6F6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M678.333 1142V1082L575 1022V1082L678.333 1142Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M575 1022L626.667 992L730 1052L678.333 1082L575 1022Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M678.333 1082L730 1052V1112L678.333 1142"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M678.333 1142V1082M678.333 1142L575 1082V1022M678.333 1142L730 1112V1052M678.333 1082L575 1022M678.333 1082L730 1052M575 1022L626.667 992L730 1052"
          stroke="#F6F6F6"
          stroke-miterlimit="10"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M158.333 1539V1479L55 1419V1479L158.333 1539Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M55 1419L106.667 1389L210 1449L158.333 1479L55 1419Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M158.333 1479L210 1449V1509L158.333 1539"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M158.333 1539V1479M158.333 1539L55 1479V1419M158.333 1539L210 1509V1449M158.333 1479L55 1419M158.333 1479L210 1449M55 1419L106.667 1389L210 1449"
          stroke="#F6F6F6"
          stroke-miterlimit="10"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M1569.67 1457V1397L1673 1337V1397L1569.67 1457Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1673 1337L1621.33 1307L1518 1367L1569.67 1397L1673 1337Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1569.67 1397L1518 1367V1427L1569.67 1457"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1569.67 1457V1397M1569.67 1457L1673 1397V1337M1569.67 1457L1518 1427V1367M1569.67 1397L1673 1337M1569.67 1397L1518 1367M1673 1337L1621.33 1307L1518 1367"
          stroke="#F6F6F6"
          stroke-miterlimit="10"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M575 944.803V1004.27L626.5 1034V974.533L575 944.803Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M626.5 974.533L678 944.803V1004.27L626.5 1034"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M678 944.736L626.5 974.6L575 944.736L626.5 915L678 944.736Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M626.5 1034L575 1004.27V944.803L626.5 974.533M626.5 1034V974.533M626.5 1034L678 1004.27V944.803L626.5 974.533M678 944.736L626.5 974.6L575 944.736L626.5 915L678 944.736Z"
          stroke="#F6F6F6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="8 8"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M1673 1260.05V1320.02L1621.5 1350V1290.03L1673 1260.05Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1621.5 1290.03L1570 1260.05V1320.02L1621.5 1350"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1570 1259.99L1621.5 1290.1L1673 1259.99L1621.5 1230L1570 1259.99Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M1621.5 1350L1673 1320.02V1260.05L1621.5 1290.03M1621.5 1350V1290.03M1621.5 1350L1570 1320.02V1260.05L1621.5 1290.03M1570 1259.99L1621.5 1290.1L1673 1259.99L1621.5 1230L1570 1259.99Z"
          stroke="#F6F6F6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="8 8"
        />
      </g>
    </svg>
  );
}
