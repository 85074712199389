import { Dispatch, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BehaviorType, LifecycleStageType } from "../__generated__/graphql";
import { BehaviorUpdate } from "../behavior/behavior_form/reducer";
import AudienceBuilderSection from "../behavior/behavior_form/builder_sections/AudienceBuilderSection";
import CompletionCriteriaBuilderSection from "../behavior/behavior_form/builder_sections/CompletionCriteriaBuilderSection";
import EntryActionsBuilderSection from "../behavior/behavior_form/builder_sections/EntryActionsBuilderSection";
import FollowUpActionsBuilderSection from "../behavior/behavior_form/builder_sections/FollowUpActionsBuilderSection";
import AudienceDrawer from "../behavior/behavior_form/drawer/AudienceDrawer";
import CompletionCriteriaDrawer from "../behavior/behavior_form/drawer/CompletionCriteriaDrawer";
import FollowUpActionsDrawer from "../behavior/behavior_form/drawer/FollowUpActionsDrawer";
import EntryActionsDrawer from "../behavior/behavior_form/drawer/EntryActionsDrawer";
import Drawer from "../patterns/Drawer";
import EmptyState from "../patterns/EmptyState";
import AttributesEmpty from "../patterns/illustrations/AttributesEmpty";
import PageHeader from "../patterns/PageHeader";
import SubSectionHeader from "../patterns/SubSectionHeader";
import ObjectiveTable from "./form/ObjectiveTable";
import EventTable from "./form/EventTable";
import EventModal from "./form/EventModal";

interface Props {
  lifecycleStage: LifecycleStageType;
  behavior: BehaviorType;
  dispatch: Dispatch<BehaviorUpdate>;
}

type DrawerComponent = typeof AudienceDrawer | typeof CompletionCriteriaDrawer;

export default function LifecycleStageForm({
  lifecycleStage,
  behavior,
  dispatch,
}: Props) {
  const navigate = useNavigate();
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [eventModalIsVisible, setEventModalIsVisible] = useState(false);
  const [DrawerComponent, setDrawerComponent] = useState<{
    Component: DrawerComponent;
  }>();
  const [events, setEvents] = useState<string[]>(
    JSON.parse(lifecycleStage.events),
  );

  const closeDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);

  const closeEventModal = useCallback(() => {
    setEventModalIsVisible(false);
  }, [setEventModalIsVisible]);

  const appendEvent = useCallback(
    (event: string) => {
      setEvents([...events, event]);
    },
    [events],
  );

  const hasEvents = useMemo(() => {
    return events?.length > 0;
  }, [events]);

  const removeEvent = useCallback(
    (event: string) => {
      setEvents(events.filter((e) => e !== event));
    },
    [events],
  );

  if (!behavior) {
    return;
  }

  const objectiveBehaviors = lifecycleStage.objectiveBehaviors;
  const hasObjectiveBehaviors = objectiveBehaviors?.length > 0;

  return (
    <>
      {eventModalIsVisible && (
        <EventModal
          lifecycleStageId={lifecycleStage.id}
          appendEvent={appendEvent}
          close={closeEventModal}
        />
      )}

      {drawerIsVisible && (
        <Drawer close={closeDrawer}>
          <DrawerComponent.Component
            dispatch={dispatch}
            behavior={behavior}
            close={closeDrawer}
          />
        </Drawer>
      )}

      <div className="flex grow flex-col mx-8 mb-8 gap-y-6">
        <section className="flex flex-col gap-2">
          <SubSectionHeader label="Entry and Completion Criteria" />
          <div className="flex flex-col bg-pavlov-bg-lighter rounded-xl shadow-sm px-6 pt-8 pb-6 gap-8">
            <AudienceBuilderSection
              behavior={behavior}
              openDrawer={() => {
                setDrawerIsVisible(true);
                setDrawerComponent({
                  Component: AudienceDrawer,
                });
              }}
              context="lifecycleStage"
            />
            <CompletionCriteriaBuilderSection
              behavior={behavior}
              openDrawer={() => {
                setDrawerIsVisible(true);
                setDrawerComponent({
                  Component: CompletionCriteriaDrawer,
                });
              }}
              context="lifecycleStage"
            />
          </div>
        </section>
        <section className="flex flex-col gap-2">
          <SubSectionHeader label="Automation" />
          <div className="flex flex-col bg-pavlov-bg-lighter rounded-xl shadow-sm px-6 pt-8 pb-6 gap-8">
            <EntryActionsBuilderSection
              behavior={behavior}
              actionList={behavior.actionsOnEnter}
              openDrawer={() => {
                setDrawerIsVisible(true);
                setDrawerComponent({
                  Component: EntryActionsDrawer,
                });
              }}
              context="lifecycleStage"
            />
            <FollowUpActionsBuilderSection
              behavior={behavior}
              actionList={behavior.actionsOnCompletion}
              openDrawer={() => {
                setDrawerIsVisible(true);
                setDrawerComponent({
                  Component: FollowUpActionsDrawer,
                });
              }}
              context="lifecycleStage"
            />
          </div>
        </section>
      </div>

      <PageHeader
        header="Objectives"
        subhead="Objectives associated with this lifecycle stage"
        buttons={[
          {
            type: "primary",
            label: "Create an Objective",
            action: () => {
              navigate(`/lifecycle_stages/${lifecycleStage.id}/objectives/new`);
            },
          },
        ]}
      />
      <div className="flex grow flex-col mb-4 gap-y-6">
        {hasObjectiveBehaviors ? (
          <div className="w-full">
            <ObjectiveTable objectives={objectiveBehaviors} />
          </div>
        ) : (
          <EmptyState
            title={`Add an Objective`}
            icon={<AttributesEmpty />}
            description={
              "Objectives are groups of events or actions you want to monitor or influence"
            }
          />
        )}
      </div>

      <PageHeader
        header="Events"
        subhead="Events associated with this lifecycle stage"
        buttons={[
          {
            type: "primary",
            label: "Associate an Event",
            action: () => {
              setEventModalIsVisible(true);
            },
          },
        ]}
      />
      <div className="flex grow flex-col mb-32 gap-y-6">
        {hasEvents ? (
          <div className="w-full">
            <EventTable
              lifecycleStageId={lifecycleStage.id}
              events={events}
              removeEvent={removeEvent}
            />
          </div>
        ) : (
          <EmptyState
            title={`Add an Event`}
            icon={<AttributesEmpty />}
            description={
              "Track events related to this particular lifecycle stage"
            }
          />
        )}
      </div>
    </>
  );
}
