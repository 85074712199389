import { useContext } from "react";
import { cx } from "./lib/cx";
import { Link, useMatch } from "react-router-dom";
import { Flags } from "./__generated__/graphql";
import useIsFeatureEnabled from "./hooks/useIsFeatureEnabled";
import DashboardIcon from "./patterns/symbols/DashboardIcon";
import EventsIcon from "./patterns/symbols/EventsIcon";
import PeopleIcon from "./patterns/symbols/PeopleIcon";
import SettingsIcon from "./patterns/symbols/SettingsIcon";
import { SessionContext } from "./SessionContext";

function NavItem({
  name,
  href,
  Icon,
}: {
  name: string;
  href: string;
  Icon: any;
}) {
  const parts = href.split("/");
  const isCurrent = useMatch(`${parts.length > 2 ? parts[1] : href}/*`);
  return (
    <li key={name}>
      <Link
        to={href}
        className={cx(
          isCurrent
            ? "bg-gradient-to-r from-grey-20 to-grey-100 text-[13px] font-semibold"
            : "hover:bg-grey-200 active:bg-grey-200",
          "group flex gap-x-3 rounded-md py-1.5 px-3 text-[13px] leading-8",
        )}
      >
        <Icon
          className={cx(isCurrent ? "text-body-text" : "bg-white", "h-6 w-6")}
          aria-hidden="true"
        />
        {name}
      </Link>
    </li>
  );
}

function SubNavItem({ href, title }: { href: string; title: string }) {
  const patchMatch = useMatch(`${href}/*`);
  const isCurrent = patchMatch?.pathname === href;
  return (
    <div
      className={cx(
        "py-2 pl-3 text-xs hover:bg-grey-200 active:bg-grey-100 rounded-md animate",
        isCurrent && "font-semibold",
      )}
    >
      <Link className="block" to={href}>
        {title}
      </Link>
    </div>
  );
}

export function Nav() {
  const sessionContext = useContext(SessionContext);
  const isReferrals = useMatch(`referrals/?/*`);
  const isDashboard = useMatch(`dashboard/?/*`);
  const isAudience = useMatch(`audience/*`);
  const isSettings = useMatch(`settings/*`);

  const isReferralsCustomer =
    sessionContext?.session?.settings.isReferralsCustomer;

  const canSeeReferralsLink =
    !isReferralsCustomer &&
    sessionContext?.session?.settings.canAccessReferrals;

  const canSeeShopifyProductsLink = useIsFeatureEnabled(Flags.ShopifyUi);

  const isImpersonating = sessionContext?.session?.isImpersonation;
  const isOnLifecycleStageAllowlist =
    sessionContext?.session?.isOnLifecycleStageAllowlist;

  const canSeeLifecycleStagesLink =
    useIsFeatureEnabled(Flags.LifecycleUi) ||
    isOnLifecycleStageAllowlist ||
    isImpersonating;

  const dashboardHref = canSeeLifecycleStagesLink
    ? "/dashboard/lifecycle_stages"
    : "/dashboard/behaviors";

  return (
    <div className="lg:fixed lg:inset-y-0 lg:flex lg:w-72 lg:flex-col mt-0 lg:mt-12">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pt-4">
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2">
                {isReferralsCustomer && (
                  <NavItem
                    name="Referrals"
                    href="/referrals"
                    Icon={() => (
                      <div className="w-5 pt-.5">
                        <DashboardIcon strokeColor={"black"} />
                      </div>
                    )}
                  />
                )}
                {isReferralsCustomer && isReferrals && (
                  <div className="pt-2 mb-1 ml-5 pl-3 border-l border-grey-200">
                    <SubNavItem href="/referrals/signals" title="Signals" />
                    <SubNavItem href="/referrals/campaigns" title="Campaigns" />
                    <SubNavItem href="/referrals/new" title="New" />
                  </div>
                )}

                <NavItem
                  name="Dashboard"
                  href={dashboardHref}
                  Icon={() => (
                    <div className="w-5 pt-.5">
                      <DashboardIcon strokeColor={"black"} />
                    </div>
                  )}
                />
                {isDashboard && (
                  <div className="pt-2 mb-1 ml-5 pl-3 border-l border-grey-200">
                    {canSeeLifecycleStagesLink && (
                      <SubNavItem
                        href="/dashboard/lifecycle_stages"
                        title="Lifecycle Stages"
                      />
                    )}
                    <SubNavItem href="/dashboard/behaviors" title="Behaviors" />
                    <SubNavItem href="/dashboard/campaigns" title="Campaigns" />
                    {canSeeReferralsLink && (
                      <SubNavItem
                        href="/dashboard/referrals"
                        title="Referrals"
                      />
                    )}
                  </div>
                )}
                <NavItem
                  name="Audience"
                  href="/audience/organizations"
                  Icon={() => (
                    <div className="w-5 pt-1.5 ">
                      <PeopleIcon />
                    </div>
                  )}
                />
                {isAudience && (
                  <div className="pt-2 mb-1 ml-5 pl-3 border-l border-grey-200">
                    <SubNavItem
                      href="/audience/organizations"
                      title="Organizations"
                    />
                    <SubNavItem href="/audience/people" title="People" />
                    <SubNavItem
                      href="/audience/attributes"
                      title="Attributes"
                    />
                  </div>
                )}
                <NavItem
                  name="Events"
                  href="/events"
                  Icon={() => (
                    <div className="w-5 pt-1">
                      <EventsIcon />
                    </div>
                  )}
                />
                <NavItem
                  name="Settings"
                  href="/settings/messaging"
                  Icon={() => (
                    <div className="w-5 pt-1.5">
                      <SettingsIcon />
                    </div>
                  )}
                />
                {isSettings && (
                  <div className="pt-2 mb-1 ml-5 pl-3 border-l border-grey-200">
                    <SubNavItem
                      href="/settings/messaging"
                      title="External Messaging"
                    />
                    <SubNavItem
                      href="/settings/loops"
                      title="Internal Messaging"
                    />
                    <SubNavItem
                      href="/settings/crms"
                      title="CRMs Integrations"
                    />
                    <SubNavItem
                      href="/settings/events"
                      title="Events Integrations"
                    />
                    <SubNavItem
                      href="/settings/incentives"
                      title="Incentives"
                    />
                    {canSeeShopifyProductsLink && (
                      <SubNavItem
                        href="/settings/incentives/shopify"
                        title="Gift Catalog"
                      />
                    )}
                  </div>
                )}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
