import { useCallback, useEffect, useRef } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { cx } from "../lib/cx";

const WIDTH_MAP = {
  sm: "320px",
  md: "640px",
  lg: "960px",
};

interface Props {
  size?: "sm" | "md" | "lg";
  useGradientBackground?: boolean;
  close: () => void;
}

function isEventInElement(event: MouseEvent, element: HTMLElement) {
  const rect = element.getBoundingClientRect();
  const x = event.clientX;
  const y = event.clientY;
  if (x < rect.left || x >= rect.right) return false;
  if (y < rect.top || y >= rect.bottom) return false;
  return true;
}

export default function Drawer({
  size = "lg",
  useGradientBackground = false,
  close,
  children,
}: Props & React.PropsWithChildren) {
  const drawerContents = useRef<HTMLDivElement>();

  const onBgClick = useCallback(
    (e: MouseEvent) => {
      const inside = isEventInElement(e, drawerContents.current);
      if (!inside) {
        close();
      }
    },
    [close, drawerContents],
  );

  useEffect(() => {
    const h = onBgClick;
    document.addEventListener("mouseup", h);

    return () => {
      document.removeEventListener("mouseup", h);
    };
  }, [onBgClick]);
  const width = WIDTH_MAP[size];

  return (
    <div
      ref={drawerContents}
      className={cx(
        "fixed h-[calc(100vh-1rem)] top-12 right-0 z-[9000] overflow-y-auto overscroll-none pb-[360px] shadow-[-8px_0_8px_-4px_rgba(0,0,0,0.050)]",
        useGradientBackground ? "bg-white" : "bg-pavlov-bg-lighter",
      )}
      style={{ width }}
      // style={{
      //   position: "fixed",
      //   height: "calc(100vh - 1rem)",
      //   width: width,
      //   top: "48px",
      //   right: "0",
      //   backgroundColor: "#f6f6f6",
      //   zIndex: 9000,
      //   boxShadow: "10px -4px 16px 19px rgba(0,0,0,0.07)",
      //   overflowY: "auto",
      //   overscrollBehavior: "none",
      //   paddingBottom: 360,
      // }}
    >
      <div className="absolute flex items-center text-center top-6 right-7 text-body-text bg-grey-50 hover:bg-blue-10 hover:text-active-blue rounded-full p-1 animate">
        <button onClick={close}>
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
      {children}
    </div>
  );
}
