import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import {
  BehaviorType,
  MachineCampaignType,
  ObjectiveCohortRiskType,
} from "../../__generated__/graphql";
import { GENERATE_PLAN_FOR_OBJECTIVE_COHORT } from "../../graphql/mutations";
import CampaignsIcon from "../../patterns/symbols/CampaignsIcon";
import PrimaryButton from "../../patterns/atoms/PrimaryButton";
import LoadingSteps from "./LoadingSteps";

export default function CampaignAction({
  objective,
  risk,
  setPlan,
}: {
  objective: BehaviorType;
  risk: ObjectiveCohortRiskType;
  setPlan: ({
    campaign,
    cohort,
  }: {
    campaign: MachineCampaignType;
    cohort: BehaviorType;
  }) => void;
}) {
  const [planLoading, setPlanLoading] = useState(false);
  const [generatePlanForObjectiveCohort] = useMutation(
    GENERATE_PLAN_FOR_OBJECTIVE_COHORT,
    { variables: { objectiveId: objective.id, cohortId: risk.cohortId } },
  );
  const handleGeneratePlan = useCallback(async () => {
    try {
      setPlanLoading(true);
      const result = await generatePlanForObjectiveCohort({
        variables: {
          objectiveId: objective.id,
          cohortId: risk.cohortId,
        },
      });
      if (result.data.generatePlanForObjectiveCohort.ok) {
        toast.success("Plan generated successfully enabled");
        setPlan({
          campaign: result.data.generatePlanForObjectiveCohort.campaign,
          cohort: result.data.generatePlanForObjectiveCohort.cohort,
        });
      }
    } catch (e) {
      toast.error(e.message);
    }
    setPlanLoading(false);
  }, [objective, risk, generatePlanForObjectiveCohort, setPlan]);

  return (
    <>
      {planLoading ? (
        <LoadingSteps />
      ) : (
        <>
          <div className="bg-white rounded-lg p-2.5 text-xs flex flex-row items-center gap-2">
            <div className="w-4 h-4">
              <CampaignsIcon strokeColor={"white"} />
            </div>
            <div className="flex flex-row items-center gap-2">
              Create Campaign:
              <span className="font-mono bg-grey-100 py-1 px-2.5 rounded-md">
                Incentivize {objective.name} ({risk.cohortName})
              </span>
            </div>
          </div>
          <div className="mt-1">
            <PrimaryButton
              onClick={handleGeneratePlan}
              label="Take action"
              size="sm"
            />
          </div>
        </>
      )}
    </>
  );
}
