import toast from "react-hot-toast";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  ExclamationCircleIcon as OutlineExclamationCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { ExclamationCircleIcon as SolidExclamationCircleIcon } from "@heroicons/react/24/solid";

import {
  ExtendedInterventionType,
  ObjectiveCohortRiskType,
  BehaviorType,
} from "../../__generated__/graphql";
import {
  GET_CAMPAIGN,
  GET_CAMPAIGNS_TARGETING_MACHINE_INSTANCE_ID,
} from "../../graphql/queries";
import { GENERATE_PLAN_FOR_OBJECTIVE_COHORT } from "../../graphql/mutations";
import { SERVICE_LEVEL_ACTIONS } from "../lib";
import SkeletonScreen from "../../patterns/Skeleton";
import EntryHeaderWrapper from "./EntryHeaderWrapper";
import SectionHeader from "../../patterns/SectionHeader";
import CampaignRow from "./CampaignRow";

export default function ObjectiveIncentivization({
  objective,
  intervention,
  isSelected,
}: {
  objective: BehaviorType;
  intervention: ExtendedInterventionType;
  isSelected: boolean;
}) {
  const associatedCohorts = intervention.cohorts;
  const hasAssociatedCohorts = associatedCohorts.length > 0;

  const totalRevenue = associatedCohorts.reduce(
    (acc: number, risk: ObjectiveCohortRiskType) => acc + risk.totalRevenue,
    0,
  );

  const completedPct = useMemo(() => {
    if (!objective) return 75;
    const entered = objective.stats.entered.count;
    const completed = objective.stats.completed.count;
    const objectiveCompletionRate =
      entered && Math.round((completed / entered) * 100);
    return objectiveCompletionRate;
  }, [objective]);

  const avgTimeToCompletion = useMemo(() => {
    if (!objective) return null;
    const avgTimeToCompletion =
      objective.stats.timeToCompletion.average.toFixed(2);
    return avgTimeToCompletion;
  }, [objective]);

  const entryDescription = useMemo(() => {
    if (!objective) return "";
    return `${completedPct}% have completed, or are on-track to complete. Average time to completion is ${avgTimeToCompletion} days`;
  }, [completedPct, avgTimeToCompletion, objective]);

  return (
    <EntryHeaderWrapper
      name={objective.name}
      description={entryDescription}
      circleFill={completedPct}
      totalRevenue={totalRevenue}
      isSelected={isSelected}
    >
      {hasAssociatedCohorts ? (
        <div id="33" className="flex flex-col">
          {associatedCohorts.map((risk: ObjectiveCohortRiskType) => (
            <div key={risk.cohortId} className="py-4 first:pt-6 ml-1.5">
              <CohortRiskPlanPrompt
                objectiveId={intervention.machineId}
                risk={risk}
              />
            </div>
          ))}
        </div>
      ) : (
        // When would this show up?!
        <div id="42" className="flex flex-col">
          <div className="flex flex-row items-center mb-2 gap-6">
            <div className="w-8 h-8 -mt-2">
              <OutlineExclamationCircleIcon strokeWidth={1} />
            </div>
            <h2 className="text-lg">No Cohort / Objective Risks found</h2>
          </div>
          <div className="pl-10 flex flex-col text-xs pb-4">
            <div className="mb-3">
              We couldn't find any risks for this objective.
            </div>
          </div>
        </div>
      )}
    </EntryHeaderWrapper>
  );
}

function CohortRiskPlanPrompt({
  objectiveId,
  risk,
}: {
  objectiveId: string;
  risk: ObjectiveCohortRiskType;
}) {
  const [plan, setPlan] = useState({
    campaign: null,
    cohort: null,
  });
  const [planLoading, setPlanLoading] = useState(false);
  const { data, loading } = useQuery(
    GET_CAMPAIGNS_TARGETING_MACHINE_INSTANCE_ID,
    {
      variables: { machineInstanceId: objectiveId, cohortId: risk.cohortId },
      fetchPolicy: "cache-and-network",
    },
  );

  const [
    getFullCampaignData,
    { data: campaignData, loading: campaignLoading },
  ] = useLazyQuery(GET_CAMPAIGN);

  const [generatePlanForObjectiveCohort] = useMutation(
    GENERATE_PLAN_FOR_OBJECTIVE_COHORT,
    { variables: { objectiveId: objectiveId, cohortId: risk.cohortId } },
  );

  useEffect(() => {
    if (data?.campaignsTargetingMachineInstanceId.length > 0) {
      setPlan({
        campaign: data.campaignsTargetingMachineInstanceId[0],
        cohort: {
          id: risk.cohortId,
          name: risk.cohortName,
        },
      });
    }
  }, [
    data?.campaignsTargetingMachineInstanceId,
    risk.cohortId,
    risk.cohortName,
  ]);

  const handleGeneratePlan = useCallback(async () => {
    try {
      setPlanLoading(true);
      const result = await generatePlanForObjectiveCohort({
        variables: {
          objectiveId: objectiveId,
          cohortId: risk.cohortId,
        },
      });
      if (result.data.generatePlanForObjectiveCohort.ok) {
        toast.success("Plan generated successfully enabled");
        setPlan({
          campaign: result.data.generatePlanForObjectiveCohort.campaign,
          cohort: result.data.generatePlanForObjectiveCohort.cohort,
        });
      }
    } catch (e) {
      toast.error(e.message);
    }
    setPlanLoading(false);
  }, [objectiveId, risk, generatePlanForObjectiveCohort]);

  useEffect(() => {
    if (plan.campaign) {
      getFullCampaignData({ variables: { id: plan.campaign.id } });
    }
  }, [plan.campaign, getFullCampaignData]);

  const fullCampaign = useMemo(() => {
    if (!campaignData) return null;
    return campaignData.campaignById;
  }, [campaignData]);

  if (loading) return <SkeletonScreen label="Talking to Agents..." />;

  return (
    <div className="flex flex-col" data-testid="cohort-risk-plan-prompt">
      {plan.campaign ? (
        <>
          <div
            className="flex flex-row items-center gap-6"
            data-testid="cohort-risk-existing-plan"
          >
            <div className="w-8 h-8 -mt-3">
              <OutlineExclamationCircleIcon
                strokeWidth={1.2}
                color="body-text-lightest"
              />
            </div>
            <SectionHeader
              title={`${risk.numberOfOrgs} ${risk.cohortName} customers at risk`}
              description={`Worth $${risk.totalRevenue.toLocaleString()}`}
              size="large"
            />
          </div>
          <div className="ml-4 pl-10 flex flex-col text-sm border-l border-body-text-lightest">
            <div className="mt-2 mb-4">
              You already have an existing campaign targeting this Objective &
              Cohort:
            </div>
            {/* Post-Plan Style */}
            {campaignLoading && <div className="spinner" />}
            {fullCampaign && <CampaignRow campaign={fullCampaign} />}
          </div>
        </>
      ) : (
        <>
          <div
            className="flex flex-row items-center gap-6"
            data-testid="cohort-risk-new-plan"
          >
            <div className="w-8 h-8 -mt-3">
              <SolidExclamationCircleIcon />
            </div>
            <SectionHeader
              title={`${risk.numberOfOrgs} ${risk.cohortName} customers at risk`}
              description={`Worth $${risk.totalRevenue.toLocaleString()}`}
              size="large"
            />
          </div>
          <div className="ml-4 pl-10 flex flex-col text-sm border-l border-dashed border-body-text-lightest">
            <div className="mt-2 mb-4">Suggested Next Steps:</div>
            <div className="flex flex-col gap-0.5 mb-3">
              {SERVICE_LEVEL_ACTIONS[risk.cohortServiceLevel](
                risk.cohortName,
              ).map((action) => (
                // Pre-Plan Style
                <div
                  key={action.name}
                  className="bg-body-text/10 bg-opacity-50 p-4 first:rounded-t-xl last:rounded-b-xl flex flex-row items-center"
                >
                  <div className="w-6 h-6 mr-3">{action.icon}</div>
                  {action.name}
                </div>
              ))}
            </div>
            <div className="flex flex-row items-center">
              {planLoading ? (
                <div className="flex flex-row btn-md animate border border-body-text text-body-text gap-2 hover:bg-body-text hover:text-white items-center">
                  Generating plan
                  <div className="w-4 h-4">
                    <div className="spinner"></div>
                  </div>
                </div>
              ) : (
                // Assign to Pavlov Button
                <>
                  <button
                    type="button"
                    className="flex flex-row btn-md animate border border-body-text text-body-text gap-1.5 hover:bg-body-text hover:text-white items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleGeneratePlan();
                    }}
                    data-testid="action-plan-button"
                  >
                    Action this plan
                    <div className="w-5 h-5">
                      <PlusCircleIcon />
                    </div>
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
