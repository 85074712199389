import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";

import { TargetMemberTypeChoices } from "../__generated__/graphql";
import { GET_LIFECYCLE_STAGE_BY_ID } from "../graphql/queries";
import { CREATE_LIFECYCLE_STAGE_OBJECTIVE } from "../graphql/mutations";

import { emptyBehavior } from "../lib/behaviors";
import { updateBehaviorReducer } from "../behavior/behavior_form/reducer";
import { isBehaviorDirty } from "../behavior/lib";

import AudienceBuilderSection from "../behavior/behavior_form/builder_sections/AudienceBuilderSection";
import CompletionCriteriaBuilderSection from "../behavior/behavior_form/builder_sections/CompletionCriteriaBuilderSection";
import AudienceDrawer from "../behavior/behavior_form/drawer/AudienceDrawer";
import CompletionCriteriaDrawer from "../behavior/behavior_form/drawer/CompletionCriteriaDrawer";
import PageHeader, { Button } from "../patterns/PageHeader";
import SkeletonScreen from "../patterns/Skeleton";
import FullScreenError from "../patterns/Errors";
import Drawer from "../patterns/Drawer";
import Breadcrumbs from "../patterns/Breadcrumbs";
import DashboardIcon from "../patterns/symbols/DashboardIcon";
import TextInput from "../patterns/forms/TextInput";

type DrawerComponent = typeof AudienceDrawer | typeof CompletionCriteriaDrawer;

export default function NewObjective() {
  const navigate = useNavigate();
  const [createObjectiveMutation] = useMutation(
    CREATE_LIFECYCLE_STAGE_OBJECTIVE,
  );
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [DrawerComponent, setDrawerComponent] = useState<{
    Component: DrawerComponent;
  }>();
  const closeDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);
  const [behaviorData, dispatch] = useReducer(updateBehaviorReducer, null);
  const [behaviorName, setBehaviorName] = useState("");

  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useQuery(GET_LIFECYCLE_STAGE_BY_ID, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (loading) return;
    const behavior = data.lifecycleStageById.behavior;
    const entryAudienceCriteria = {
      criteria: [
        {
          type: "filter",
          version: "1",
          filter: {
            version: "1",
            filters: {
              operator: "AND",
              conditions: [
                {
                  path: `entry::${behavior.id}`,
                  source: "machine_metadata",
                  target: behavior.targetMemberType.toLowerCase(),
                  operator: "NOT_NULL",
                },
              ],
            },
          },
        },
      ],
    };
    dispatch({
      type: "init",
      data: {
        ...emptyBehavior,
        audience: JSON.stringify(entryAudienceCriteria),
      },
    });
  }, [data, loading]);

  const lifecycleStage = data?.lifecycleStageById;

  const createObjective = useCallback(async () => {
    const variables = {
      lifecycleStageId: lifecycleStage.id,
      name: behaviorName,
      targetMemberType: behaviorData.targetMemberType,
      status: behaviorData.status,
      audience: JSON.stringify(behaviorData.audience),
      observing: JSON.stringify(behaviorData.observing),
      actionsOnEnter: JSON.stringify(behaviorData.actionsOnEnter),
      actionsOnCompletion: JSON.stringify(behaviorData.actionsOnCompletion),
      actionsWhileObserving: JSON.stringify(behaviorData.actionsWhileObserving),
    };

    try {
      const response = await createObjectiveMutation({ variables: variables });

      if (response.data.createLifecycleStageObjective.ok) {
        navigate(`/lifecycle_stages/${lifecycleStage.id}?view=settings`);
        toast.success("Objective created successfully");
      } else {
        toast.error(() => <p>Could not create Objective</p>);
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [
    behaviorData,
    lifecycleStage,
    behaviorName,
    createObjectiveMutation,
    navigate,
  ]);

  const isDirty = useMemo(
    () => isBehaviorDirty(behaviorData, emptyBehavior),
    [behaviorData],
  );

  if (loading || !behaviorData) return <SkeletonScreen />;
  if (error) return <FullScreenError message={error.message} />;

  let button = {
    label: "Create Objective",
    type: "primary",
    isDisabled: !isDirty,
    action: () => {
      createObjective();
    },
  } as Button;

  return (
    <>
      {drawerIsVisible && (
        <Drawer close={closeDrawer}>
          <DrawerComponent.Component
            dispatch={dispatch}
            behavior={behaviorData}
            close={closeDrawer}
          />
        </Drawer>
      )}

      <Breadcrumbs
        crumbs={[
          { label: "Dashboard", link: "/dashboard/lifecycle_stages" },
          { label: lifecycleStage.name, link: `/lifecycle_stages/${id}` },
          { label: "New Objective" },
        ]}
        icon={<DashboardIcon strokeColor={"white"} strokeWidth={1.5} />}
      />

      <PageHeader
        header={`Create a new Objective`}
        subhead={`Lifecycle Stage: ${lifecycleStage.name}`}
        buttons={[button]}
        slug={`Targeting: ${
          behaviorData.targetMemberType === TargetMemberTypeChoices.Person
            ? "Individuals"
            : "Organizations"
        }`}
      />

      <div className="flex grow flex-col mx-8 mb-8 gap-y-6">
        <div className="bg-white rounded-lg shadow-sm">
          <div className="p-6 pb-2 border-b-1 border-rule-color border-dashed">
            <div className="max-w-lg">
              <TextInput
                label="Choose a descriptive name for this Objective"
                value={behaviorName}
                placeholder="Objective name"
                required={true}
                onChange={setBehaviorName}
                autoFocus={true}
              />
            </div>
          </div>
          <div className="p-6 border-b-1 border-rule-color border-dashed">
            <AudienceBuilderSection
              behavior={behaviorData}
              openDrawer={() => {
                setDrawerIsVisible(true);
                setDrawerComponent({
                  Component: AudienceDrawer,
                });
              }}
              context="objective"
            />
          </div>
          <div className="p-6 pb-4 border-b-">
            <CompletionCriteriaBuilderSection
              behavior={behaviorData}
              openDrawer={() => {
                setDrawerIsVisible(true);
                setDrawerComponent({
                  Component: CompletionCriteriaDrawer,
                });
              }}
              context="objective"
            />
          </div>
        </div>
      </div>
    </>
  );
}
