import { useParams } from "react-router-dom";
import OrgProfile from "./OrgProfile";

export default function OrgMain() {
  const params = useParams();
  return (
    <>
      <div className="max-w-8xl mx-auto mt-0">
        <OrgProfile id={params.id} renderBreadcrumbs={true} />
      </div>
    </>
  );
}
