import { cx } from "../lib/cx";
import SlackLogo from "../patterns/symbols/SlackLogo";
import {
  TagIcon,
  EnvelopeIcon,
  SparklesIcon,
  CursorArrowRaysIcon,
  ClockIcon,
  RssIcon,
  ArrowUpCircleIcon,
  DocumentChartBarIcon,
} from "@heroicons/react/24/solid";

interface Props {
  title: string | React.ReactNode;
  section?: string;
  blockColor?: string;
  errors?: string[];
  onClick?: () => void;
  children?: React.ReactNode;
}

const iconMap = {
  email: EnvelopeIcon,
  incentive: SparklesIcon,
  events: CursorArrowRaysIcon,
  "follow-up": EnvelopeIcon,
  attributes: TagIcon,
  "auto-exit": ClockIcon,
  notify: SlackLogo,
  webhook: RssIcon,
  "update-crm": ArrowUpCircleIcon,
  summary: DocumentChartBarIcon,
};

export default function SettingsBlock({
  title,
  section,
  onClick,
  errors = [],
  children,
}: Props) {
  const IconComponent = iconMap[section] || TagIcon;
  const conditionalClass =
    title === "Please choose an incentive" ||
    title === "Sending email with no subject" ||
    errors.length > 0
      ? "bg-yellow-50 text-yellow-800 border-yellow-50"
      : "";

  return (
    <div
      onClick={onClick}
      className={cx(
        "flex items-center py-2 px-3 text-xs leading-6 tracking-wide rounded-lg hover:text-active-blue bg-pavlov-bg-dark border border-grey-50 hover:border-blue-10 hover:bg-blue-10 animate cursor-pointer",
        conditionalClass,
      )}
    >
      <div className={cx("flex h-4 w-4 mr-2 rounded-md my-auto")}>
        <IconComponent />
      </div>
      <div className="whitespace-nowrap align-middle text-xs leading-6 pr-2">
        {errors.length === 0
          ? title
          : errors.map((error) => <div className="">{error}</div>)}
      </div>
      {children}
    </div>
  );
}
