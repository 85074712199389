import {
  ExtendedInterventionType,
  BehaviorType,
} from "../../__generated__/graphql";
import { InterventionTypes } from "../lib";
import ObjectiveIncentivization from "./ObjectiveIncentivization";
import CampaignEngagementIncentivization from "./CampaignEngagementIncentivization";
import UpsellCohort from "./UpsellCohort";

export default function InterventionEntry({
  intervention,
  objective,
  isSelected,
}: {
  intervention: ExtendedInterventionType;
  objective: BehaviorType;
  isSelected: boolean;
}) {
  return (
    <>
      {intervention.type === InterventionTypes.IncentiviseObjective && (
        <ObjectiveIncentivization
          intervention={intervention}
          objective={objective}
          isSelected={isSelected}
        />
      )}
      {intervention.type === InterventionTypes.ImproveCampaignEngagement && (
        <CampaignEngagementIncentivization
          intervention={intervention}
          isSelected={isSelected}
        />
      )}
      {intervention.type === InterventionTypes.UpsellCohort && (
        <UpsellCohort intervention={intervention} isSelected={isSelected} />
      )}
    </>
  );
}
