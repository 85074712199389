export default function DashboardIcon({ strokeColor, strokeWidth = 2 }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3036_51438)">
        <path
          d="M15 19.5L10.5 16.7518L10.5 11.2549L15 14.0031M15 19.5V14.0031M15 19.5L19.5 16.7518V11.2549L15 14.0031M19.5 11.2487L15 14.0093L10.5 11.2487L15 8.5L19.5 11.2487Z"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 12.5068L15 15.4934M19.5 12.7L15 15.5001"
          stroke="#1E293B"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 13.5L15 16.4865M19.5 13.6932L15 16.4933"
          stroke="#1E293B"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 14.5L15 17.4865M19.5 14.6932L15 17.4933"
          stroke="#1E293B"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 15.5L15 18.4865M19.5 15.6932L15 18.4933"
          stroke="#1E293B"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 3.25488L10.5 8.75178L15 11.5V6.00309L10.5 3.25488Z"
          fill="white"
        />
        <path d="M15 6.00309L19.5 3.25488V8.75178L15 11.5" fill="white" />
        <path
          d="M19.5 3.24869L15 6.00928L10.5 3.24869L15 0.5L19.5 3.24869Z"
          fill="white"
        />
        <path
          d="M15 11.5L10.5 8.75178L10.5 3.25488L15 6.00309M15 11.5V6.00309M15 11.5L19.5 8.75178V3.25488L15 6.00309M19.5 3.24869L15 6.00928L10.5 3.24869L15 0.5L19.5 3.24869Z"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 15.2549L4 20.7518L8.5 23.5V18.0031L4 15.2549Z"
          fill="white"
        />
        <path d="M8.5 18.0031L13 15.2549L13 20.7518L8.5 23.5" fill="white" />
        <path
          d="M13 15.2487L8.5 18.0093L4 15.2487L8.5 12.5L13 15.2487Z"
          fill="white"
        />
        <path
          d="M8.5 23.5L4 20.7518L4 15.2549L8.5 18.0031M8.5 23.5V18.0031M8.5 23.5L13 20.7518L13 15.2549L8.5 18.0031M13 15.2487L8.5 18.0093L4 15.2487L8.5 12.5L13 15.2487Z"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 7.25488L4 12.7518L8.5 15.5V10.0031L4 7.25488Z"
          fill="white"
        />
        <path d="M8.5 10.0031L13 7.25488L13 12.7518L8.5 15.5" fill="white" />
        <path
          d="M13 7.24869L8.5 10.0093L4 7.24869L8.5 4.5L13 7.24869Z"
          fill="white"
        />
        <path
          d="M8.5 15.5L4 12.7518L4 7.25488L8.5 10.0031M8.5 15.5V10.0031M8.5 15.5L13 12.7518L13 7.25488L8.5 10.0031M13 7.24869L8.5 10.0093L4 7.24869L8.5 4.5L13 7.24869Z"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 8.60664L8.49999 11.2934M13 8.5L8.49999 11.3001"
          stroke="#1E293B"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 9.60664L8.49999 12.2934M13 9.5L8.49999 12.3001"
          stroke="#1E293B"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 10.6066L8.49999 13.2934M13 10.5L8.49999 13.3001"
          stroke="#1E293B"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 11.6066L8.49999 14.2934M13 11.5L8.49999 14.3001"
          stroke="#1E293B"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3036_51438">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
