import { ReactNode } from "react";
import { Link } from "react-router-dom";

export default function Breadcrumbs({
  crumbs,
  icon,
}: {
  crumbs: { label: string; link?: string }[];
  icon: ReactNode;
}) {
  return (
    <nav aria-label="Breadcrumb" className="top-0 z-[9001] flex items-center">
      <div className="p-7 pb-3 flex items-center text-sm leading-5 text-body-text">
        <div className="mr-2 h-7 w-7 rounded-md bg-grey-200">
          <div className="w-5 h-5 mx-auto mt-1">{icon}</div>
        </div>
        <ol className="flex items-center">
          {crumbs.map((crumb, index) => (
            <li key={index} className="flex items-center">
              {crumb.link ? (
                <Link
                  to={crumb.link}
                  className="hover:text-active-blue animate font-semibold"
                >
                  {crumb.label}
                </Link>
              ) : (
                <span>{crumb.label}</span>
              )}
              {index !== crumbs.length - 1 && (
                <span className="mx-1.5 text-grey-400">\</span>
              )}
            </li>
          ))}
        </ol>
      </div>
    </nav>
  );
}
