import { useMemo, useState, useEffect } from "react";

const LOADING_STEPS = [
  "Collecting audience...",
  "Composing emails...",
  "Writing slack messages...",
  "Setting up actions...",
  "Analyzing engagement patterns...",
  "Optimizing delivery sequences...",
  "Calibrating response metrics...",
  "Synchronizing communication channels...",
  "Finalizing details...",
];

export default function LoadingSteps() {
  const [loadingStep, setLoadingStep] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingStep((current) =>
        current < LOADING_STEPS.length - 1 ? current + 1 : current,
      );
    }, 2500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const currentStep = useMemo(() => LOADING_STEPS[loadingStep], [loadingStep]);
  const lastStep = useMemo(
    () => LOADING_STEPS?.[loadingStep - 1] || "",
    [loadingStep],
  );

  return (
    <div className="relative h-16 flex flex-row items-start gap-4">
      <div className="spinner" />
      <div className="relative flex-1">
        <div
          key={currentStep}
          className={`
            absolute left-0 w-full
            flex flex-row items-center gap-2 font-semibold
            animate-fade-in
            opacity-100
            transition-opacity duration-500 ease-in-out
          `}
        >
          {currentStep}
        </div>
        <div
          key={lastStep}
          className={`
            absolute left-0 w-full
            flex flex-row items-center gap-2 font-semibold
            animate-fade-out
            opacity-0
            transform translate-y-8
            transition-all duration-500 ease-in-out
          `}
        >
          {lastStep}
        </div>
      </div>
    </div>
  );
}
