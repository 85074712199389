import { useCallback, useContext, useMemo, useState } from "react";
import { SessionContext } from "../SessionContext";
import { Menu } from "@headlessui/react";
import { cx } from "../lib/cx";
import toast from "react-hot-toast";
import UserProfile from "../settings/UserProfile";
import PavlovProductLogoBlack from "./symbols/PavlovProductLogoBlack";
import { NavLink } from "react-router-dom";
import {
  ArrowUturnLeftIcon,
  UserIcon,
  LockClosedIcon,
  UsersIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { Flags } from "../__generated__/graphql";
import useIsFeatureEnabled from "../hooks/useIsFeatureEnabled";
import ApiKey from "../settings/ApiKey";
import { Link } from "react-router-dom";

import Drawer from "../patterns/Drawer";
import AiAgentDrawer from "../ai_agent/AiAgentDrawer";
import FluxIcon from "./symbols/FluxIcon";

const menuItemStyle =
  "animate block px-4 py-4 text-sm rounded-lg hover:bg-grey-100 cursor-pointer whitespace-no-wrap border-none";

export default function TopBar({
  isImpersonating,
  logout,
}: {
  isImpersonating: boolean;
  logout: () => void;
}) {
  const [userProfileVisible, setUserProfileVisible] = useState(false);
  const [apiKeyVisible, setApiKeyVisible] = useState(false);
  const [aiDrawerVisible, setAiDrawerVisible] = useState(false);
  const closeDrawer = useCallback(() => {
    setAiDrawerVisible(false);
  }, [setAiDrawerVisible]);

  const sessionContext = useContext(SessionContext);

  const name = sessionContext.userProfile?.name;
  const initials = useMemo(() => {
    if (!name) {
      return "UN";
    }
    const names = name.split(" ");
    if (names.length >= 2) {
      return names[0][0].toUpperCase() + names[1][0].toUpperCase();
    }
    return "UN";
  }, [name]);

  const canSeeAiDrawer = useIsFeatureEnabled(Flags.AiDrawer);

  return (
    <>
      {aiDrawerVisible && (
        <Drawer size="md" useGradientBackground={true} close={closeDrawer}>
          <AiAgentDrawer close={closeDrawer} />
        </Drawer>
      )}
      {userProfileVisible && (
        <UserProfile closeModal={() => setUserProfileVisible(false)} />
      )}
      {apiKeyVisible && <ApiKey closeModal={() => setApiKeyVisible(false)} />}
      <nav className="bg-pavlov-bg top-0 z-[9001] flex h-12 items-center align-middle lg:pl-4 lg:pr-4 fixed w-full">
        <div className="flex grow">
          <NavLink className="my-auto ml-2.5 h-[32px] w-[96px]" to="/">
            <PavlovProductLogoBlack />
          </NavLink>
          <div className="align-middle flex-auto">
            {isImpersonating && (
              <div className="mx-8 p-1 bg-red-400 text-white rounded-full flex flex-row items-center">
                <div className="flex-grow">
                  <ExclamationTriangleIcon className="w-5 inline-block mx-2" />
                  You are currently impersonating a customer
                </div>
                <button
                  className="px-3 py-1 bg-red-500 hover:bg-red-600 animate rounded-full text-sm font-semibold"
                  onClick={logout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
          <Menu as="div" className="relative">
            <Menu.Button
              className={cx(
                "group animate align-middle items-end pl-4 py-2.5 bg-white text-xs text-body-text rounded-full mr-3 hover:bg-active-blue hover:text-white",
              )}
            >
              {name || "Unknown"}
              <span className="animate ml-2 text-[9px] text-body-text rounded-full border-2 border-white bg-grey-50 group-hover:bg-white/65 p-2.5 tracking-wide group-hover:border-active-blue">
                {initials}
              </span>
            </Menu.Button>
            <Menu.Items className="bg-white rounded-lg shadow-md p-4 absolute w-60 mt-3 right-0">
              <div className="mb-4">
                <Menu.Item
                  as="div"
                  className={menuItemStyle}
                  onClick={() => {
                    setUserProfileVisible(true);
                  }}
                >
                  <UserIcon className="w-4 inline-block mr-3 " />
                  Your Profile
                </Menu.Item>
                <Menu.Item
                  as="div"
                  className={menuItemStyle}
                  onClick={() => {
                    setApiKeyVisible(true);
                  }}
                >
                  <LockClosedIcon className="w-4 inline-block mr-3" />
                  Your API Key
                </Menu.Item>
              </div>
              <div className="text-sm py-3 border-t border-rule-color">
                <div className="block px-4 py-2 text-[10px] uppercase tracking-widest whitespace-no-wrap border-none ">
                  <span className="text-body-text-darker">
                    {sessionContext.session.companyName}
                  </span>
                </div>
                <Menu.Item as="div">
                  {({ close }) => (
                    <Link
                      to="/teammates"
                      className={menuItemStyle}
                      onClick={close}
                    >
                      <UsersIcon className="w-4 inline-block mr-3" />
                      Teammates
                    </Link>
                  )}
                </Menu.Item>
              </div>
              <div className="text-sm pt-3 border-t border-rule-color ">
                <Menu.Item
                  as="div"
                  className={menuItemStyle}
                  onClick={() => {
                    fetch("/app/logout").then((e) => {
                      if (e.status === 200) {
                        window.location.replace("/login");
                      } else {
                        toast.error("Failed to logout");
                      }
                    });
                  }}
                >
                  <ArrowUturnLeftIcon className="w-4 inline-block mr-3" />
                  Logout
                </Menu.Item>
              </div>
            </Menu.Items>
          </Menu>
        </div>
        {canSeeAiDrawer && (
          <div
            className="flex cursor-pointer w-9 h-9 bg-white rounded-full items-center justify-center"
            onClick={() => {
              setAiDrawerVisible(true);
            }}
          >
            <div className="w-6 h-6 text-body-text animate-pulse-slow">
              <FluxIcon />
            </div>
          </div>
        )}
      </nav>
    </>
  );
}
