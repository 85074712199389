import { useContext, useMemo, useState } from "react";
import {
  BehaviorType,
  ExtendedInterventionType,
  LifecycleStageType,
} from "../../__generated__/graphql";
import { SessionContext } from "../../SessionContext";
import FluxIcon from "../../patterns/symbols/FluxIcon";
import ClimbingNumber from "../../patterns/ClimbingNumber";
import InterventionEntry from "./InterventionEntry";

const bgMap = {
  0: "bg-[#E5D1C6]",
  1: "bg-[#EBCAB7]",
  2: "bg-[#F2C3A9]",
  3: "bg-[#F8BC9A]",
  4: "bg-[#FFB58B]",
  5: "bg-[#FFAE7D]",
  6: "bg-[#FFA76F]",
};

interface ObjectiveBehaviorWrapper {
  behavior: BehaviorType;
  subBehaviors: BehaviorType[];
}

export default function InterventionList({
  lifecycleStage,
  interventionData,
  objectives,
}: {
  lifecycleStage: LifecycleStageType;
  interventionData: ExtendedInterventionType[];
  objectives: ObjectiveBehaviorWrapper[];
}) {
  const [selectedTab, setSelectedTab] = useState<number | null>(null);
  const sessionContext = useContext(SessionContext);
  const name = sessionContext.userProfile?.name;
  const firstName = useMemo(() => {
    return name ? name.split(" ")[0] : "Agent";
  }, [name]);

  const totalRevenue = useMemo(() => {
    return lifecycleStage.totalRevenueAtRisk;
  }, [lifecycleStage]);

  const currentHour = new Date().getHours();
  const greeting =
    currentHour < 12
      ? "Good morning"
      : currentHour < 18
        ? "Good afternoon"
        : "Good evening";

  const objectiveMap = useMemo(() => {
    const map = {};
    objectives.forEach((objective) => {
      if (objective?.behavior) {
        return (map[objective?.behavior.id] = objective);
      }
    });
    return map;
  }, [objectives]);

  return (
    <>
      <div className="mx-8 mb-4" data-testid="intervention-list-container">
        <div className="w-full overflow-hidden rounded-xl">
          <div className="bg-pavlov-bg-darkest rounded-t-xl flex flex-col gap-4 text-4xl leading-[48px] p-10 pb-12 tracking-wide font-light text-body-text-light">
            <div className="flex items-center gap-x-3">
              <div className="rounded-full object-cover bg-white/40 w-12 h-12 grid place-items-center ">
                <div className="w-8 h-8 animate-pulse-slow text-body-text-lightest">
                  <FluxIcon />
                </div>
              </div>
              <div className="text-base">
                <div className="font-semibold tracking-tight">Pavlov AI</div>
                <span className="font-normal mt-1 tracking-normal">Online</span>
              </div>
            </div>
            <p className="max-w-screen-sm fade-in-up ">
              {greeting} {firstName},<br />
              <span className="font-bold tracking-tight inline">
                {"$"}
                <ClimbingNumber value={totalRevenue} />
              </span>{" "}
              revenue is on the line today.
            </p>
            <p className="max-w-screen-sm fade-in-up">
              Here are the key issues and some proposed plans to get things back
              on track:
            </p>
          </div>
          {interventionData.map((intervention, index) => {
            const isSelected = selectedTab === index;
            const objective =
              objectiveMap[intervention.machineId]?.behavior || null;
            return (
              <div
                key={index}
                onClick={() => setSelectedTab(isSelected ? null : index)}
                className={`cursor-pointer transition-all ease-in-out duration-800 rounded-t-xl px-8 pt-4 pb-6 -mt-2.5
                  ${bgMap[index]}
                  ${isSelected ? "h-auto" : "h-auto"}
                `}
                data-testid={`intervention-list-item-${intervention.machineId}`}
              >
                <InterventionEntry
                  intervention={intervention}
                  isSelected={isSelected}
                  objective={objective}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
