import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_CRM_INTEGRATIONS } from "../graphql/queries";
import { CrmIntegrationType } from "../__generated__/graphql";
import PageHeader from "../patterns/PageHeader";
import { Outlet, useNavigate } from "react-router-dom";
import EmptyState from "../patterns/EmptyState";
import Integrations from "../patterns/illustrations/Integrations";
import IntegrationDetail from "./IntegrationDetail";
import CardMenu from "../patterns/CardMenu";
import SectionHeader from "../patterns/SectionHeader";

export default function CRMsMain() {
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_CRM_INTEGRATIONS);
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [activeIntegration, setActiveIntegration] =
    useState<CrmIntegrationType>();

  if (loading) {
    return <></>;
  }
  return (
    <div>
      {drawerIsVisible && activeIntegration && (
        <IntegrationDetail
          close={() => setDrawerIsVisible(false)}
          activeIntegration={activeIntegration}
        />
      )}

      <div className="flex flex-col max-w-8xl mx-auto mt-0">
        {data.crmIntegrations.length > 0 ? (
          <>
            <PageHeader
              header="CRM Integrations"
              subhead=""
              buttons={[
                {
                  type: "primary",
                  label: "Add a CRM integration",
                  action: () => {
                    navigate("./new");
                  },
                },
              ]}
            />
            <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-8">
              {data.crmIntegrations.map((integration) => (
                <CardMenu
                  key={integration.id}
                  onClick={() => {
                    setDrawerIsVisible(true);
                    setActiveIntegration(integration);
                  }}
                >
                  <div className="w-auto h-40 rounded-t-lg bg-gradient-to-br from-[#E5F5F2] to-[#E4ECF6] p-6 flex items-center justify-center">
                    <Integrations />
                  </div>
                  <div className="px-4 pt-4 pb-2">
                    <SectionHeader
                      title={integration.crmType}
                      description="CRM"
                    />
                  </div>
                </CardMenu>
              ))}
            </div>
          </>
        ) : (
          <div className="mt-8">
            <EmptyState
              title="No CRM integrations configured yet"
              description="You haven't configured any CRM integration yet."
              icon={<Integrations />}
              buttonLabel={"Add a CRM integration"}
              onClick={() => navigate("./new")}
            />
          </div>
        )}
        <Outlet />
      </div>
    </div>
  );
}
