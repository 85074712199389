export default function PersonIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" fill="white" />
      <path
        d="M8 21.0032L8 11.0089L16 6L24 11.0089V21.0032L16 26L8 21.0032Z"
        fill="white"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 26V15.9995M16 15.9995L24 11M16 15.9995L8 11"
        stroke="#1E293B"
        strokeWidth="0.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_1987_60329">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
