import { useCallback, useEffect, useMemo, useReducer, useState } from "react";

import DashboardIcon from "../patterns/symbols/DashboardIcon";
import Breadcrumbs from "../patterns/Breadcrumbs";
import { useParams, useSearchParams } from "react-router-dom";
import { CampaignOverview } from "./CampaignOverview";
import { useQuery } from "@apollo/client";
import { GET_CAMPAIGN } from "../graphql/queries";
import {
  FilterBuilderContext,
  FilterBuilderContextType,
  getFilterBuilderContext,
} from "../FilterBuilderContext";
import CampaignStatusPill from "../campaigns/CampaignStatePill";
import { MemberTable } from "./MemberTable";
import PageHeader from "../patterns/PageHeader";
import Drawer from "../patterns/Drawer";
import {
  PersonType,
  OrgType,
  MachineStatusChoices,
  TargetMemberTypeChoices,
} from "../__generated__/graphql";
import TabNavigation from "../patterns/TabNavigation";
import CampaignForm from "../campaign_form";
import { updateCampaignDefinition } from "../campaign_form/reducer";
import { useCampaignButtons, Tabs, completionCriteriaDescription } from "./lib";
import FullScreenError from "../patterns/Errors";
import SkeletonScreen from "../patterns/Skeleton";
import PersonProfile from "../person/PersonProfile";
import OrgProfile from "../org/OrgProfile";

import DeleteModal from "./modals/DeleteModal";
import SetLiveModal from "./modals/SetLiveModal";
import MessageStats from "./MessageStats";
import CompleteModal from "./modals/CompleteModal";
import ArchiveModal from "./modals/ArchiveModal";

export const VIEW_PARAM = "view";

interface Props {
  isBehavior?: boolean;
}

export default function Campaign({ isBehavior = false }: Props) {
  const params = useParams();

  let [searchParams, setSearchParams] = useSearchParams();

  let view = searchParams.get(VIEW_PARAM) ?? "overview";
  let from = searchParams.get("from");

  const { loading, error, data } = useQuery(GET_CAMPAIGN, {
    variables: { id: params.id },
    fetchPolicy: "cache-and-network",
  });
  const [campaignDefinition, dispatch] = useReducer(
    updateCampaignDefinition,
    null,
  );
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const closeDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);

  const [activeTab, setActiveTab] = useState(Tabs.Eligible);
  const [activeMember, setActiveMember] = useState<PersonType | OrgType>();

  const filterBuilderContext: FilterBuilderContextType = useMemo(() => {
    return getFilterBuilderContext(from);
  }, [from]);

  const campaignIsOrgType =
    data?.campaignById.targetMemberType === TargetMemberTypeChoices.Org;
  const tabs = useMemo(
    () => [
      {
        label: "Overview",
        action: () => setSearchParams(`${VIEW_PARAM}=overview`),
        isActive: view === "overview",
      },
      {
        label: "Settings",
        action: () => setSearchParams(`${VIEW_PARAM}=settings`),
        isActive: view === "settings",
      },
      data?.campaignById?.status !== MachineStatusChoices.Draft &&
        !isBehavior && {
          label: "Email stats",
          action: () => setSearchParams(`${VIEW_PARAM}=email_stats`),
          isActive: view === "email_stats",
        },
    ],
    [setSearchParams, data?.campaignById?.status, view, isBehavior],
  );

  useEffect(() => {
    if (data?.campaignById.definition) {
      dispatch({
        type: "init",
        data: JSON.parse(data.campaignById.definition),
      });
    }
  }, [data?.campaignById.definition]);
  const [deleteCampaignModalVisible, setDeleteCampaignModalVisible] =
    useState(false);
  const [setLiveModalVisible, setSetLiveModalVisible] = useState(false);
  const [completeModalVisible, setCompleteModalVisible] = useState(false);
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);
  const buttons = useCampaignButtons(
    data?.campaignById,
    campaignDefinition,
    setDeleteCampaignModalVisible,
    setSetLiveModalVisible,
    setCompleteModalVisible,
    setArchiveModalVisible,
  );

  if (loading) {
    return <SkeletonScreen />;
  }

  if (error) {
    return <FullScreenError message={error.message} />;
  }

  const audienceKey = campaignIsOrgType ? "allOrgs" : "allPeople";

  return (
    <div>
      {deleteCampaignModalVisible && (
        <DeleteModal
          campaign={data?.campaignById}
          close={() => setDeleteCampaignModalVisible(false)}
        />
      )}
      {setLiveModalVisible && (
        <SetLiveModal
          campaign={data?.campaignById}
          campaignDefinition={campaignDefinition}
          close={() => setSetLiveModalVisible(false)}
        />
      )}
      {completeModalVisible && (
        <CompleteModal
          campaign={data?.campaignById}
          close={() => setCompleteModalVisible(false)}
        />
      )}
      {archiveModalVisible && (
        <ArchiveModal
          campaign={data?.campaignById}
          close={() => setArchiveModalVisible(false)}
        />
      )}

      <Breadcrumbs
        crumbs={[
          { label: "Dashboard", link: "/dashboard/campaigns" },
          { label: data.campaignById.name },
        ]}
        icon={<DashboardIcon strokeColor={"white"} strokeWidth={1.5} />}
      />

      {drawerIsVisible && activeMember && (
        <Drawer close={closeDrawer}>
          <div className="mt-10">
            {activeMember.__typename === "PersonType" ? (
              <PersonProfile id={activeMember.id} />
            ) : (
              <OrgProfile id={activeMember.id} />
            )}
          </div>
        </Drawer>
      )}

      <div className="flex flex-col max-w-8xl mx-auto mt-0">
        <PageHeader
          header={data.campaignById.name}
          headerPill={<CampaignStatusPill status={data.campaignById.status} />}
          subhead={
            campaignDefinition &&
            completionCriteriaDescription(
              campaignDefinition.completion_criteria,
            )
          }
          buttons={buttons}
          slug={`Targeting: ${
            data.campaignById.targetMemberType ===
            TargetMemberTypeChoices.Person
              ? "Individuals"
              : "Organizations"
          }`}
        />
        <TabNavigation tabs={tabs} />
        {view === "overview" && (
          <>
            <CampaignOverview
              campaign={data.campaignById}
              maxAudienceSize={data[audienceKey].total}
              setActiveTab={setActiveTab}
            />
            <MemberTable
              campaign={data.campaignById}
              showMember={(m: PersonType | OrgType) => {
                setActiveMember(m);
                setDrawerIsVisible(true);
              }}
              activeTab={activeTab}
            />
          </>
        )}
        {view === "settings" && !loading && campaignDefinition && (
          <FilterBuilderContext.Provider value={filterBuilderContext}>
            <CampaignForm
              dispatch={dispatch}
              data={data}
              campaignDefinition={campaignDefinition}
            />
          </FilterBuilderContext.Provider>
        )}
        {view === "email_stats" && !loading && campaignDefinition && (
          <MessageStats
            campaign={data.campaignById}
            campaignDefinition={campaignDefinition}
            showMember={(m: PersonType | OrgType) => {
              setActiveMember(m);
              setDrawerIsVisible(true);
            }}
          />
        )}
      </div>
    </div>
  );
}
