import toast from "react-hot-toast";
import { useCallback } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/24/outline";

import { REMOVE_LIFECYCLE_STAGE_EVENT } from "../../graphql/mutations";
import Table, { TableProps } from "../../patterns/tables/table";

function toTable(
  events: string[],
  navigate: NavigateFunction,
  removeEvent: (event: string) => void,
): Omit<TableProps, "filterBar"> {
  return {
    header: [{ label: "Name", width: "w-4/5" }, { label: "Delete" }],
    rows: events.map((e) => {
      return {
        id: e,
        onClick: () => {},
        cells: [
          {
            content: (
              <div
                className="flex flex-col min-w-80"
                onClick={(e) => {
                  navigate(`/events/${e}`);
                  e.stopPropagation();
                }}
              >
                <span className="text-sm font-medium tracking-tight text-body-text">
                  {e}
                </span>
              </div>
            ),
          },
          {
            content: (
              <span
                className="cursor-pointer font-normal text-red-600"
                onClick={() => removeEvent(e)}
              >
                <TrashIcon className="w-4 h-4" />
              </span>
            ),
          },
        ],
      };
    }),
  };
}

export default function EventTable({
  lifecycleStageId,
  events,
  removeEvent,
}: {
  lifecycleStageId: string;
  events: string[];
  removeEvent: (event: string) => void;
}) {
  const navigate = useNavigate();
  const [removeStageEventMutation] = useMutation(REMOVE_LIFECYCLE_STAGE_EVENT);
  const removeStageEvent = useCallback(
    async (event: string) => {
      const response = await removeStageEventMutation({
        variables: {
          lifecycleStageId: lifecycleStageId,
          eventName: event,
        },
      });
      if (response.data.removeLifecycleStageEvent.ok) {
        toast.success(`Event removed from Lifecycle Stage`);
        removeEvent(event);
      } else {
        toast.error(`Failed to remove event from Lifecycle Stage`);
      }
    },
    [lifecycleStageId, removeStageEventMutation, removeEvent],
  );

  const tableDefinition = toTable(events, navigate, removeStageEvent);

  return <Table header={tableDefinition.header} rows={tableDefinition.rows} />;
}
