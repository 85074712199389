import toast from "react-hot-toast";
import { useCallback, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { GET_EVENTS } from "../../graphql/queries";
import { ASSOCIATE_LIFECYCLE_STAGE_EVENT } from "../../graphql/mutations";
import Modal from "../../patterns/Modal";
import SecondaryButton from "../../patterns/atoms/SecondaryButton";
import PrimaryButton from "../../patterns/atoms/PrimaryButton";
import SkeletonScreen from "../../patterns/Skeleton";
import SelectInput from "../../patterns/forms/SelectInput";

export default function EventModal({
  lifecycleStageId,
  appendEvent,
  close,
}: {
  lifecycleStageId: string;
  appendEvent: (event: string) => void;
  close: () => void;
}) {
  const [eventName, setEventName] = useState("");
  const { data, loading } = useQuery(GET_EVENTS);
  const [associateEventMutation] = useMutation(ASSOCIATE_LIFECYCLE_STAGE_EVENT);
  const associateEvent = useCallback(async () => {
    const response = await associateEventMutation({
      variables: {
        lifecycleStageId: lifecycleStageId,
        eventName: eventName,
      },
    });
    if (response.data.associateLifecycleStageEvent.ok) {
      toast.success(`Event associated with Lifecycle Stage`);
      appendEvent(eventName);
    } else {
      toast.error(() => <p>Could not associate event</p>);
    }
  }, [lifecycleStageId, eventName, associateEventMutation, appendEvent]);

  if (loading) {
    return <SkeletonScreen label="Loading events" />;
  }

  return (
    <Modal title={`Associate an Event`} close={close}>
      <div className="align-middle justify-center items-center w-full h-32 pb-2">
        <SelectInput
          label={"Event"}
          description={"Pick an event"}
          placeholder="Pick an event"
          required={true}
          value={eventName}
          options={data?.allEvents.map((event) => ({
            label: event,
            value: event,
          }))}
          onChange={(s) => setEventName(s)}
        />
      </div>
      <div className="flex justify-center mt-6 gap-2">
        <SecondaryButton label="Cancel" onClick={close} />
        <PrimaryButton
          label="Associate Event"
          isDisabled={!eventName}
          onClick={() => {
            associateEvent();
            close();
          }}
        />
      </div>
    </Modal>
  );
}
