import { useState } from "react";
import { Popover } from "@headlessui/react";
import {
  BinaryFilterCondition,
  UnaryFilterCondition,
} from "../types/BackendTypes";
import {
  AttributeType,
  Config,
  attrIcon,
  formatOperator,
  formatValue,
} from "./lib";
import FilterConditionEditor from "./FilterConditionEditor";
import { cx } from "../lib/cx";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { AttributeGroup } from "../SessionContext";

interface Props {
  index: number;
  editorIsOpen: boolean;
  condition: BinaryFilterCondition | UnaryFilterCondition;
  filterConfig: Config;
  firstInGroup: boolean;
  lastInGroup: boolean;
  readonly: boolean;
  availableAttributes: AttributeGroup;
  openEditor: () => void;
  closeEditor: () => void;
  onChange: (
    n: BinaryFilterCondition | UnaryFilterCondition | undefined,
    index: number,
  ) => void;
}

export default function ConditionBlock({
  condition,
  editorIsOpen,
  filterConfig,
  index,
  firstInGroup,
  lastInGroup,
  readonly,
  availableAttributes,
  openEditor,
  closeEditor,
  onChange,
}: Props) {
  const [isHovered, setIsHovered] = useState(false);
  if (readonly) {
    return (
      <ConditionBlockContents
        isHovered={isHovered}
        firstInGroup={firstInGroup}
        lastInGroup={lastInGroup}
        readonly={readonly}
        onChange={onChange}
        filterConfig={filterConfig}
        condition={condition}
        availableAttributes={availableAttributes}
        index={index}
      />
    );
  }

  return (
    <Popover onClick={(e) => e.stopPropagation()}>
      <Popover.Button
        onClick={() => (editorIsOpen ? closeEditor() : openEditor())}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <ConditionBlockContents
          isHovered={isHovered}
          firstInGroup={firstInGroup}
          lastInGroup={lastInGroup}
          readonly={readonly}
          onChange={onChange}
          filterConfig={filterConfig}
          condition={condition}
          availableAttributes={availableAttributes}
          index={index}
        />
      </Popover.Button>
      {editorIsOpen && (
        <Popover.Panel static className="absolute z-10 bg-white shadow-lg">
          <FilterConditionEditor
            condition={condition}
            config={filterConfig}
            onChange={(n?: BinaryFilterCondition) => {
              onChange(n, index);
            }}
            closeEditor={closeEditor}
          />
        </Popover.Panel>
      )}
    </Popover>
  );
}

interface ConditionBlockContentsProps {
  isHovered: boolean;
  firstInGroup: boolean;
  lastInGroup: boolean;
  readonly: boolean;
  condition: BinaryFilterCondition | UnaryFilterCondition;
  availableAttributes: AttributeGroup;
  filterConfig: Config;
  index: number;
  onChange: (
    n: BinaryFilterCondition | UnaryFilterCondition | undefined,
    index: number,
  ) => void;
}

function ConditionBlockContents({
  condition,
  availableAttributes,
  isHovered,
  firstInGroup,
  lastInGroup,
  readonly,
  filterConfig,
  index,
  onChange,
}: ConditionBlockContentsProps) {
  const IconComponent = attrIcon(condition.source, filterConfig.dataType);
  const attribute = availableAttributes[condition.target]?.find(
    (attr: AttributeType) => attr.name === condition.path,
  );
  const conditionBlockName = attribute?.displayName ?? condition.path;
  return (
    <div
      className={cx(
        "flex py-2 pl-3 pr-5 h-10 text-xs leading-6 tracking-wide animate cursor-pointer",
        isHovered
          ? "text-active-blue bg-blue-10"
          : "text-body-text bg-pavlov-bg-dark",
        firstInGroup ? "rounded-l-lg" : "",
        lastInGroup && readonly ? "rounded-r-lg" : "",
      )}
    >
      <div className="relative flex group text-body-text hover:text-active-blue animate">
        <div className={cx("flex h-4 w-4 mr-2 rounded-md my-auto")}>
          <IconComponent />
        </div>
        {condition.target === "event" ? (
          <>
            Has {condition.operator === "NULL" && "not"} performed event{" "}
            <strong>&nbsp; {condition.path}</strong>
          </>
        ) : (
          <>
            {conditionBlockName} {formatOperator(condition)}
            <strong>
              &nbsp; {formatValue(filterConfig.dataType, condition)}
            </strong>
          </>
        )}
        {isHovered && (
          <div className="absolute top-0 right-0 -mt-2 -mr-5 w-3/5 h-10 bg-gradient-to-r from-blue-10/0 via-blue-10/90 to-blue-10/100 opacity-0 transition-opacity ease-in-out duration-500 group-hover:opacity-100">
            <XMarkIcon
              className="absolute top-0 right-0 mt-2.5 mr-2 w-5 h-5"
              onClick={(e) => {
                e.stopPropagation();
                onChange(null, index);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
