import { useMutation } from "@apollo/client";
import {
  MachineCampaignType,
  MachineStatusChoices,
} from "../__generated__/graphql";
import { Button } from "../patterns/PageHeader";
import {
  PAUSE_CAMPAIGN,
  UPDATE_CAMPAIGN_DEFINITION,
} from "../graphql/mutations";
import { useCallback, useMemo } from "react";
import { CampaignDefinition } from "../campaign_form/types";
import toast from "react-hot-toast";
import {
  AnyOfEventSetCondition,
  AttrCompletionCriteria,
  CompletionCriteria,
  EventCompletionCriteria,
  EventCountCondition,
  EventSetCondition,
} from "../types/BackendTypes";
import { titleCase } from "../lib/string";

export enum Tabs {
  Eligible = "eligible",
  Completed = "completed",
  TimeToCompletion = "timeToCompletion",
  Exited = "exited",
}

export function useCampaignButtons(
  campaign: MachineCampaignType,
  campaignDefinition: CampaignDefinition,
  setDeleteModalVisible: (b: boolean) => void,
  setSetLiveModalVisible: (b: boolean) => void,
  setCompleteModalVisible: (b: boolean) => void,
  setArchiveModalVisible: (b: boolean) => void,
) {
  const [updateCampaignMutation] = useMutation(UPDATE_CAMPAIGN_DEFINITION);
  const [pauseMutation] = useMutation(PAUSE_CAMPAIGN);

  const saveCampaign = useCallback(() => {
    return updateCampaignMutation({
      variables: {
        id: campaign.id,
        definition: JSON.stringify(campaignDefinition),
      },
    });
  }, [campaign, campaignDefinition, updateCampaignMutation]);

  const buttons = useMemo(() => {
    if (!campaign) {
      return [];
    }
    const isBehavior = campaign.isBehavior;
    const entityName = isBehavior ? "behavior" : "campaign";
    const isDirty =
      JSON.stringify(campaignDefinition) !==
      JSON.stringify(JSON.parse(campaign?.definition || "[]"));
    const buttonList = [];
    switch (campaign.status) {
      case MachineStatusChoices.Live:
        buttonList.push({
          label: `Complete ${entityName}`,
          type: entityName,
          action: () => setCompleteModalVisible(true),
        } as Button);
        buttonList.push({
          label: `Pause ${entityName}`,
          type: entityName,
          action: () => {
            pauseMutation({ variables: { id: campaign.id } }).then(() =>
              toast.success(`${entityName} paused`),
            );
          },
        } as Button);
        break;
      case MachineStatusChoices.Draft:
        buttonList.push({
          label: `Set ${entityName} live`,
          type: entityName,
          action: () => setSetLiveModalVisible(true),
        } as Button);
        break;
      case MachineStatusChoices.Complete:
        buttonList.push({
          label: "Archive",
          type: entityName,
          action: () => setArchiveModalVisible(true),
        } as Button);
        break;
      case MachineStatusChoices.Paused:
        buttonList.push({
          label: `Complete ${entityName}`,
          type: entityName,
          action: () => setCompleteModalVisible(true),
        } as Button);
        buttonList.push({
          label: `Set ${entityName} live`,
          type: entityName,
          action: () => setSetLiveModalVisible(true),
        } as Button);
        break;
      default:
        buttonList.push({
          label: `Set ${entityName} live`,
          type: entityName,
          action: () => setSetLiveModalVisible(true),
        } as Button);
    }
    if (campaign.status !== MachineStatusChoices.Complete) {
      buttonList.unshift({
        label: "Save",
        type: "secondary",
        isDisabled: !isDirty,
        action: () => {
          saveCampaign()
            .then(() => toast.success(`${titleCase(entityName)} saved`))
            .catch((e) =>
              toast.error(`Failed to save ${entityName}}: ${e.message}`),
            );
        },
      } as Button);
    }
    if (campaign.stats.entered.count === 0) {
      buttonList.unshift({
        label: "Delete",
        type: "secondary",
        action: () => {
          setDeleteModalVisible(true);
        },
      } as Button);
    }
    return buttonList;
  }, [
    campaign,
    campaignDefinition,
    saveCampaign,
    setDeleteModalVisible,
    setSetLiveModalVisible,
    setArchiveModalVisible,
    setCompleteModalVisible,
    pauseMutation,
  ]);
  return buttons;
}

export function isEventCriteria(
  c: CompletionCriteria,
): c is EventCompletionCriteria {
  return c?.type === "event";
}

export function isEventSetCondition(
  c?: EventSetCondition | EventCountCondition | AnyOfEventSetCondition,
): c is EventSetCondition {
  return c?.type === "event_set";
}

export function isEventCountCondition(
  c?: EventSetCondition | EventCountCondition | AnyOfEventSetCondition,
): c is EventCountCondition {
  return c?.type === "event_count";
}

export function isAttrCriteria(
  c: CompletionCriteria,
): c is AttrCompletionCriteria {
  return c?.type === "attribute";
}

export function hasValidCriteria(criteria): boolean {
  if (isAttrCriteria(criteria)) {
    return criteria.condition.filter.filters.conditions.length > 0;
  } else if (isEventCriteria(criteria)) {
    if (isEventSetCondition(criteria.condition)) {
      return criteria.condition.events.length > 0;
    } else if (isEventCountCondition(criteria.condition)) {
      return Object.keys(criteria.condition.events).length > 0;
    }
    return !!criteria.target;
  }
  return false;
}

export function completionCriteriaDescription(
  completionCriteria: CompletionCriteria,
) {
  if (isEventCriteria(completionCriteria)) {
    if (isEventSetCondition(completionCriteria.condition)) {
      return completionCriteria.condition.events.join(", ");
    }
    if (isEventCountCondition(completionCriteria.condition)) {
      const eventName = Object.keys(completionCriteria.condition.events)[0];
      const count = completionCriteria.condition.events[eventName];
      return `${eventName} ${count} times`;
    }
    return `${completionCriteria.target}`;
  } else {
    return "";
  }
}
