
/*
This is a generated file. Do not edit it directly.
To add to this file, edit the TO_TRANSLATE list in pavlov/management/commands/translate_constants.py file.
*/

export enum AttributeSources {
  Hubspot = "hubspot",
  Mixpanel = "mixpanel",
  Segment = "segment",
  Api = "api",
  Computed = "computed",
  Referral = "pavlov_referrals",
  Focus = "pavlov_focus",
  MachineMetadata = "machine_metadata",
  EventMetadata = "events_metadata",
  LifecycleStage = "lifecycle_stage",
  Cohort = "cohort",
  PavlovRevops = "pavlov_revops",
  TruenorthSnowflake = "truenorth_snowflake",
  ConjuraSnowflake = "conjura_snowflake",
}

export enum AttributeContexts {
  ObjectiveCampaign = "OBJECTIVE_CAMPAIGN",
  LifecycleOverview = "LIFECYCLE_OVERVIEW",
}

export enum MachineAttributePrefixes {
  MachineMetadataCurrentMember = "member::",
  MachineMetadataLastEntry = "entry::",
  MachineMetadataLastExit = "exit::",
  MachineMetadataLastCompleted = "completed::",
  CohortCurrentMember = "current_member::",
}

export enum LifecycleStageRagStatus {
  Red = "Red",
  Amber = "Amber",
  Green = "Green",
}

