import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import Table, { TableProps } from "../patterns/tables/table";
import SearchInput from "../patterns/forms/SearchInput";
import {
  MachineCampaignType,
  MachineStatusChoices,
  TargetMemberTypeChoices,
} from "../__generated__/graphql";
import CampaignStatusPill from "./CampaignStatePill";
import SearchSelect from "../patterns/forms/SearchSelect";
import { titleCase } from "../lib/string";
import PersonIcon from "../patterns/symbols/PersonIcon";
import OrganizationIcon from "../patterns/symbols/OrganizationIcon";
import { CampaignDefinition } from "../types/BackendTypes";
import { completionCriteriaDescription } from "../campaign/lib";

interface Props {
  campaigns: MachineCampaignType[];
  totalPeople: number;
  filter?: string;
  search?: string;
  setSearch?: (s: string) => void;
  updateFilter?: (s: string) => void;
  sortKey?: string;
  setSortKey?: (s: string) => void;
  margin?: boolean; // default true
}

function toTable(
  campaigns: MachineCampaignType[],
  totalPeople: number,
  navigate: NavigateFunction,
): Omit<TableProps, "filterBar"> {
  return {
    header: [
      { label: "", width: "w-12" },
      { label: "Name", width: "w-1/5" },
      { label: "Status" },
      { label: "Entered →", width: "w-1/5" },
      { label: "Completed →", width: "w-1/5" },
      { label: "Average time to completion", width: "w-1/5" },
    ],
    rows: campaigns
      .filter((f) => !f.deleted)
      .map((c) => {
        const definition: CampaignDefinition = JSON.parse(c.definition);
        return {
          id: c.id,
          onClick: () => navigate(`/campaigns/${c.id}`),
          cells: [
            {
              content: (
                <div className="flex flex-col items-left align-top mr-3 text-grey-400">
                  <div className="w-8 h-8 p-0.5 bg-white rounded-md">
                    {c.targetMemberType === TargetMemberTypeChoices.Person ? (
                      <PersonIcon />
                    ) : (
                      <OrganizationIcon />
                    )}
                  </div>
                </div>
              ),
            },
            {
              content: (
                <div className="flex flex-col min-w-80">
                  <Link to={`/campaigns/${c.id}`}>
                    <span className="text-sm font-medium tracking-tight text-body-text">
                      {c.name}
                    </span>
                  </Link>
                  <span className="text-xs text-body-text-lightest leading-5">
                    {completionCriteriaDescription(
                      definition.completion_criteria,
                    )}
                  </span>
                </div>
              ),
            },
            { content: <CampaignStatusPill status={c.status} /> },
            {
              content: (
                <div className="my-auto mr-4 h-8 flex flex-col">
                  {c.status === MachineStatusChoices.Draft ? (
                    "-"
                  ) : (
                    <>
                      <span className="font-bold text-sm text-body-text">
                        {c.stats.entered.count}
                      </span>
                      <span className="text-body-text-lightest">
                        {Math.round(
                          (c.stats.entered.count / totalPeople) * 100,
                        )}
                        % of all{" "}
                        {c.targetMemberType === TargetMemberTypeChoices.Org
                          ? "organizations"
                          : "people"}
                      </span>
                    </>
                  )}
                </div>
              ),
            },
            {
              content: (
                <div className="my-auto mr-4 h-8 flex flex-col">
                  {c.status === MachineStatusChoices.Draft ? (
                    "-"
                  ) : (
                    <>
                      <span className="font-bold text-sm text-body-text">
                        {c.stats.completed.count}
                      </span>
                      <span className="text-body-text-lightest">
                        {c.stats.entered.count > 0
                          ? Math.round(
                              (c.stats.completed.count /
                                c.stats.entered.count) *
                                100,
                            )
                          : "0"}
                        % of eligible
                        {c.targetMemberType === TargetMemberTypeChoices.Org
                          ? " organizations"
                          : " people"}
                      </span>
                    </>
                  )}
                </div>
              ),
            },
            {
              content: (
                <div className="my-auto mr-4 h-8 flex flex-col">
                  {c.status === MachineStatusChoices.Draft ? (
                    "-"
                  ) : (
                    <>
                      <span className="font-bold text-sm text-body-text">
                        {Math.round(c.stats.timeToCompletion.average)}
                      </span>{" "}
                      <span className="text-body-text-lightest">days</span>
                    </>
                  )}
                </div>
              ),
            },
          ],
        };
      }),
  };
}

const campaignStates: Array<"ALL" | "-" | MachineStatusChoices> = [
  "ALL",
  ...Object.values(MachineStatusChoices).filter(
    (c) => c !== MachineStatusChoices.Archived,
  ),
];

export default function CampaignTable({
  campaigns,
  totalPeople,
  filter,
  search,
  setSearch,
  updateFilter,
  sortKey,
  setSortKey,
  margin,
}: Props) {
  const navigate = useNavigate();
  const tableDefinition = toTable(campaigns, totalPeople, navigate);
  const stateOptions = campaignStates.map((s) => {
    return { label: titleCase(s), value: s, disabled: false };
  });
  stateOptions.push({ label: "--------", value: "-", disabled: true });
  stateOptions.push({
    label: titleCase(MachineStatusChoices.Archived),
    value: MachineStatusChoices.Archived,
    disabled: false,
  });

  return (
    <Table
      header={tableDefinition.header}
      rows={tableDefinition.rows}
      margin={margin} // default is set to true
      filterBar={
        !!setSearch &&
        !!updateFilter &&
        !!sortKey &&
        !!setSortKey && (
          <div
            className="flex justify-between"
            data-testid="campaign-table-filter-bar"
          >
            <div className="flex">
              <div>
                <SearchInput
                  value={search}
                  placeholder="Search campaigns…"
                  onChange={setSearch}
                />
              </div>
              <div>
                <SearchSelect
                  label="Campaign Status"
                  options={stateOptions}
                  onChange={updateFilter}
                  value={filter}
                />
              </div>
            </div>
            <div>
              <SearchSelect
                label="Sort by"
                options={
                  [
                    { label: "Date created", value: "started" },
                    { label: "Name", value: "name" },
                  ] as Array<{ label: string; value: string }>
                }
                value={sortKey}
                onChange={setSortKey}
              />
            </div>
          </div>
        )
      }
    />
  );
}
