import { useMemo } from "react";
import { SparklesIcon } from "@heroicons/react/24/outline";
import {
  ExtendedInterventionType,
  ObjectiveCohortRiskType,
} from "../../__generated__/graphql";
import StatsHeading from "../../patterns/StatsHeading";
import EntryHeaderWrapper from "./EntryHeaderWrapper";

export default function CampaignEngagementIncentivization({
  intervention,
  isSelected,
}: {
  intervention: ExtendedInterventionType;
  isSelected: boolean;
}) {
  const parsedSupportingData = JSON.parse(intervention.supportingData);
  const { sent, open, bounce, unsubscribe } =
    parsedSupportingData.message_stats;
  const openRate = (open / sent) * 100;
  const bounceRate = (bounce / sent) * 100;

  const cohortRisks = intervention.cohorts;

  const totalRevenue = cohortRisks.reduce(
    (acc: number, risk: ObjectiveCohortRiskType) => acc + risk.totalRevenue,
    0,
  );

  const entryDescription = useMemo(() => {
    return `Out of ${sent} emails, ${open} (${openRate.toFixed(0)}%) were opened.`;
  }, [sent, open, openRate]);

  return (
    <EntryHeaderWrapper
      name={intervention.name}
      description={entryDescription}
      circleFill={openRate}
      totalRevenue={totalRevenue}
      isSelected={isSelected}
    >
      <div
        className="flex flex-col pb-4 ml-12"
        data-testid="campaign-engagement-incentivization"
      >
        <div className="flex items-center ml-4 mt-4 max-w-screen-sm wrap-balance">
          <p className="text-md">{intervention.plan}</p>
        </div>
        <div className="flex flex-row text-sm m-4 w-full gap-0.5 rounded-xl overflow-hidden">
          <div className="bg-body-text/10 p-4 w-full flex">
            <StatsHeading title="Sent: " stat={sent} />
          </div>
          <div className="bg-body-text/10 p-4 w-full flex">
            <StatsHeading title="Open Rate" stat={`${openRate.toFixed(2)}%`} />
          </div>
          <div className="bg-body-text/10 p-4 w-full flex">
            <StatsHeading
              title="Bounce Rate"
              stat={`${bounceRate.toFixed(2)}%`}
            />
          </div>
          <div className="bg-body-text/10 p-4 w-full flex">
            <StatsHeading title="Unsubscribed" stat={unsubscribe} />
          </div>
        </div>
        <div className="flex flex-row items-center ml-4 max-w-screen-sm wrap-balance">
          <p className="text-md ">
            After analyzing the content, I've identified improvements in the
            structure and tone that could boost engagement and increase open and
            click rates.{" "}
            <span className="mt-2 font-bold tracking-tight block">
              Would you like me to optimize the content?
            </span>
          </p>
        </div>
        <div className="flex flex-row items-center m-4 text-xs">
          <button
            type="button"
            className="flex flex-row btn-md animate border border-body-text text-body-text gap-1.5 hover:bg-body-text hover:text-white items-center"
            onClick={(e) => {
              e.stopPropagation();
            }}
            data-testid="optimize-campaign-content-button"
          >
            Optimize Campaign Content
            <div className="w-5 h-5">
              <SparklesIcon />
            </div>
          </button>
        </div>
      </div>
    </EntryHeaderWrapper>
  );
}
